import { getUID, urlToPayload, filterString, getCurrentTime, getDropdownItem } from './../js/custom';
import { getRelativeStatuses } from './../js/dropdown-service';
import { StatusSue, StatusCase, DateFormat } from './../js/constants';
import { toBeautyFormat } from './../js/date-helper';
import db from './../_helpers/fake-db';
import store from './../_store'
import manageStorage from './../js/manage-localstorage'
import { item_cancelBeforeFong } from '../views/case/update-status/update-status-service';



export default {
    getAll,
    postStatus,
    editStatus,
    getOption,
    deleteAction
};

function getAll(url, opts) {
    let state = store.state.caseinfo;

    let items = []
    if (state.useLocalStorage) {
        if (!_.isEmpty(manageStorage.getObject(state.storageSue))) {
            items = manageStorage.getObject(state.storageSue).items;
        }
    }

    return {
        items
    }
}

function getOption(url, opts) {
    let body = opts.body;
    let { statusCase, statusSue } = body;
    let stCaseId = statusCase.id

    switch (stCaseId) {
        case StatusCase['รอวันฟ้องจากทนาย'].id:
            {
                return {
                    items: _.toArray(StatusSue.kamnodWanFong.option)
                }
            }
        case StatusCase['แจ้งถอนฟ้อง'].id:
            {
                return {
                    items: _.toArray(StatusSue.kamnodWanTonFong.option)
                }
            }
        case StatusCase['รอถอนฟ้อง'].id:
            {
                return {
                    items: [StatusSue.yuenFong.option.tonFong]
                }
            }
        case StatusCase['แจ้งงดหมายตั้ง'].id:
            {
                return {
                    items: _.toArray(StatusSue.kamnodWanNgodMaiTang.option)
                }
            }
        case StatusCase['รอถอนหมายตั้ง'].id:
            {
                return {
                    items: [StatusSue.alreadyNgodMaiTang.option.alreadyNgodMaiTang]
                }
            }
        case StatusCase['ส่งคำฟ้องให้ทนายแล้ว'].id:
            {
                return {
                    items: _.toArray(StatusSue.waiting.option)
                }
            }
        case StatusCase['ระหว่างยื่นฟ้อง'].id: {
            let stSueId = statusSue.id
            switch (stSueId) {
                case StatusSue.yuenFong.id:
                    return {
                        items: _.toArray(StatusSue.yuenFong.option)
                    }
                case StatusSue.piPakSa.id:
                    return {
                        items: _.toArray(StatusSue.piPakSa.option)
                    }
                case StatusSue.namKamBangKub.id:
                    return {
                        items: _.toArray(StatusSue.namKamBangKub.option)
                    }
                case StatusSue.praneePranom.id:
                    return {
                        items: _.toArray(StatusSue.praneePranom.option)
                    }
                default: return { items: [] }
            }
        }
    }

}



function postStatus(url, opts) {
    let body = opts.body;
    const { statusCaseBeforePost, statusSueBeforePost, option, details } = body;

    // let newStatus = getNewStatus(option.id, statusSueBeforePost)
    let newStatus = getNewStatus({ option, statusSueBeforePost })
    let state = store.state.caseinfo;
    let sueItems = _.cloneDeep(state.sueItems);

    let order = sueItems.length + 1

    const responseModel = {
        id: getUID(),
        statusCaseBeforePost: statusCaseBeforePost,
        statusSueBeforePost: statusSueBeforePost,
        statusCaseAfterPost: newStatus.statusCase,
        statusSueAfterPost: newStatus.statusSue,
        option: option,
        postedBy: {
            id: "AAAAA",
            text: "AAAAAA " + order
        },
        canDelete: true,
        isEdited: false,
        editedDate: null,
        postedDate: getCurrentTime(),
        details: details,
        order: order
    };




    sueItems.push(responseModel);


    return {
        items: sueItems
    }
}


function editStatus(url, opts) {
    let body = opts.body;



    let responseModel = _.cloneDeep(body);

    responseModel.canDelete = true;
    responseModel.isEdited = true;
    responseModel.editedDate = getCurrentTime();
    responseModel.editedBy = {
        id: "AAAAA",
        text: "AAAAAA"
    };


    let state = store.state.caseinfo;
    let sueItems = _.cloneDeep(state.sueItems);
    let index = sueItems.findIndex(x => x.id === body.id);
    sueItems.splice(index, 1, responseModel)


    return {
        items: sueItems
    }
}

function deleteAction(url, opts) {
    let body = opts.body;

    let state = store.state.caseinfo;
    let sueItems = _.cloneDeep(state.sueItems);
    let index = sueItems.findIndex(x => x.id === body.id);
    sueItems.splice(index, 1)


    return {
        items: sueItems
    }
}



export function getNewStatus({ option, statusSueBeforePost }) {
    switch (option.id) {
        case StatusSue.kamnodWanFong.option.kamnodWanFong.id:
            {
                return {
                    statusCase: getDropdownItem(
                        StatusCase["เตรียมเอกสารฟ้อง"]
                    ),
                    statusSue: null
                };
            }
        case StatusSue.kamnodWanTonFong.option.kamnodWanTonFong.id:
            {
                return {
                    statusCase: getDropdownItem(
                        StatusCase["รอถอนฟ้อง"]
                    ),
                    statusSue: getDropdownItem(StatusSue.yuenFong)
                };
            }

        case StatusSue.kamnodWanNgodMaiTang.option.kamnodWanNgodMaiTang.id:
            {
                return {
                    statusCase: getDropdownItem(
                        StatusCase["รอถอนหมายตั้ง"]
                    ),
                    statusSue: statusSueBeforePost
                };
            }

        case StatusSue.alreadyNgodMaiTang.option.alreadyNgodMaiTang.id:
            {
                let statusCase = getDropdownItem(StatusCase['ปิดเคส'])
                statusCase.reason = getDropdownItem(StatusCase['ปิดเคส'].reason['งดหมายตั้ง'])

                return {
                    statusCase,
                    statusSue: getDropdownItem(StatusSue.ngodMaiTang)
                };
            }

        case StatusSue.waiting.option.fong.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']),
                    statusSue: getDropdownItem(StatusSue.yuenFong)
                };
            }

        case StatusSue.waiting.option.luen.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ส่งคำฟ้องให้ทนายแล้ว']),
                    statusSue: null
                };
            }

        case StatusSue.yuenFong.option.luen.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']),
                    statusSue: getDropdownItem(StatusSue.yuenFong)
                };
            }

        case StatusSue.yuenFong.option.piPakSa.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']),
                    statusSue: getDropdownItem(StatusSue.piPakSa)
                };
            }

        case StatusSue.yuenFong.option.praneePranom.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']),
                    statusSue: getDropdownItem(StatusSue.praneePranom)
                };
            }

        case StatusSue.yuenFong.option.tonFong.id:
            {
                let statusCase = getDropdownItem(StatusCase['ปิดเคส'])
                statusCase.reason = getDropdownItem(StatusCase['ปิดเคส'].reason['ถอนฟ้อง'])
                return {
                    statusCase,
                    statusSue: getDropdownItem(StatusSue.tonFong)
                };
            }


        case StatusSue.piPakSa.option.namKamBangKub.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']),
                    statusSue: getDropdownItem(StatusSue.namKamBangKub)
                };
            }


        case StatusSue.namKamBangKub.option.ookmaiTang.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']),
                    statusSue: getDropdownItem(StatusSue.ookmaiTang)
                };
            }


        case StatusSue.praneePranom.option.ookmaiTang.id:
            {
                return {
                    statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']),
                    statusSue: getDropdownItem(StatusSue.ookmaiTang)
                };
            }
    }
}