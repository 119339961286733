
import { getToken } from './../js/authorization'
import { UserRole } from './../js/constants'


function get(url, opts) {
    if (getToken().role != UserRole.LAWYER) {
        return mockEmployeeProfile();
    } else {
        return mockLawyerProfile();
    }
}

function mockLawyerProfile() {
    return {
        "namePrefix": {
            "id": "05e4389e-6562-996d-93fc-8189bcdb9d2e",
            "text": "นางสาว"
        },
        "firstName": "ทิพย์จันทร์",
        "lastName": "สายยศ",
        "id": "f6baaf7f-ef6f-41db-91d1-15b3661f4b05"
    }
}



function mockEmployeeProfile() {
    return {
        "namePrefix": {
            "id": "977416de-6516-a083-5cfa-5d3d5cb97c41",
            "text": "นาย"
        },
        "userName": "thetiktest",
        "firstName": "test",
        "lastName": "test",
        "email": "test2@xx.com",
        "role": {
            "id": "BACK_OFFICE",
            "text": "BACK_OFFICE"
        },
        "id": "262b2bb5-58c0-4d48-c5d4-08d7a61690c1"
    }
}


function update(url, opts) {
    log(opts.body);
    let request = opts.body;
    return request
}



export default {
    get,
    update,

};

