import Vue from 'vue';
import Vuex from 'vuex';

import master from './master.module';
import pageinfo from './pageinfo.module';
import app from './app.module';
import caseinfo from './caseinfo.module';
import loading from './loading.module';


Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        master,
        pageinfo,
        caseinfo,
        loading,
        app,
    }
});
