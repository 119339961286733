import * as http from './../js/service'
import { StatusSue, StatusCase, UserRole, StatusPayment, PaymentPart, ContractType } from './../js/constants'
import { getNamePrefixString } from './../js/custom'
import manageStorage from './../js/manage-localstorage'
import store from './../_store/index'

import { fakeAbbr, getFakeUrl } from './../_helpers/fake-url'
import UtilsMixins from './../_mixins/utils-mixins';

import db from "./../_helpers/fake-db";



export default {
    namespaced: true,
    state: {
        useLocalStorage: false,
        storageCase: "case",
        storageSue: "sue-items",
        paymentStorage: "paymentStorage",
        id: null,
        dialogName: false,
        dialogAddr: false,
        dialogUpload: false,
        selectedFileType: null,
        ncbModel: {
            consider: "",
            showPanel: null,
            ncbRejectReason: null,
            describe: "",
            checkListFiles: []
        },
        payments: [],
        sueItems: [],
        // contracts: [],
        model: {
            statusCase: null,
            statusSue: null,
            creditor: null,
            lawyer: null,
            court: null,
            caseNumber: null,
            deptor: {
                namePrefix: null,
                namePrefixRemark: null,
                name: {
                    firstName: null,
                    lastName: null
                },
                address: {
                    houseNumber: null,
                    moo: null,
                    trok: null,
                    soi: null,
                    road: null,
                    province: null,
                    district: null,
                    subDistrict: null,
                    telNo: null,
                    faxNo: null,
                    isActive: false
                },

                nationality: {

                },
                occupation: null,
                age: null,
                id: null,
            },
            idCardNo: null,
            noticeDate: null,
            noticePrintDate: null,
            plaintDate: null,
            deptSummaryDate: null,
            plaintDetail: null,
            generatePlaintURL: null,
            remark: null,
            issueDate: null,
            issuePlace: null,
            id: null,
            pauseFlag: false,
            pauseReason: null,
        }
    },
    mutations: {
        setStatusSue(state, val) {
            let obj = _.cloneDeep(state.model);
            Object.assign(obj, {
                statusSue: val
            });
            state.model = obj;
        },
        setStatusCase(state, val) {
            let obj = _.cloneDeep(state.model);
            Object.assign(obj, {
                statusCase: val
            });
            state.model = obj;
        },
        setPlaintDate(state, val) {
            let obj = _.cloneDeep(state.model);
            Object.assign(obj, {
                plaintDate: val
            });

            state.model = obj;
        },
        updatePayment(state, val) {
            state.payments = val.items;
        }
    },
    getters: {
        statusCaseText: (state) => {
            let str = ""
            if (state.model && state.model.statusCase) {
                str = state.model.statusCase.text;
                if (state.model.statusCase.id === StatusCase['ปิดเคส'].id && state.model.statusCase.reason) {
                    str += ` (${state.model.statusCase.reason.text})`
                }
            }
            return str
        },
        statusSueText: (state) => {
            if (state.model && state.model.statusSue) {
                return state.model.statusSue?.text;
            }
            return ""
        },
        statusNCBText: (state) => {
            if (state.ncbModel && state.ncbModel.status) {
                return state.ncbModel?.status?.text;
            }
            return ""
        },
        pauseReason: (state) => {
            if (state.model && state.model.pauseReason) {
                return state.model.pauseReason;
            }
            return ""
        },
        deptorName: (state) => {
            let deptor = state.model.deptor;
            if (deptor.name) {
                let prefix = getNamePrefixString(deptor);
                let name = deptor.name;

                return `${prefix}${name.firstName} ${name.lastName}`;
            } else {
                return null;
            }
        },
        conditionStatusCaseNotificationToLawyer: (state) => {
            if (![UserRole.LAWYER].includes(UtilsMixins.computed.currentRole())) {
                return false
            }

            let statusCount = [
                StatusCase["รอวันฟ้องจากทนาย"].id,
                StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id,
                StatusCase["แจ้งถอนฟ้อง"].id,
                StatusCase["แจ้งงดหมายตั้ง"].id,
            ];

            let { statusCase } = state.model
            if (
                statusCase &&
                statusCount.includes(statusCase.id)
            ) {
                return true;
            }

            return false;
        },
        countPaymentBadgeToShowBackOffice: (state) => {
            let count = 0;
            if ([UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE].includes(UtilsMixins.computed.currentRole())) {
                let statusCount = [
                    StatusPayment["รออนุมัติ"].id,
                    StatusPayment["อนุมัติแล้ว"].id
                ];


                count = state.payments.filter(x => statusCount.includes(x.status.id)).length;
            } else {
                count = 0
            }

            return count;
        },
        countReadyToRequestPayment: (state, getters) => {
            let count = 0;
            if ([UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE].includes(UtilsMixins.computed.currentRole())) {
                count = 0
            } else if ([UserRole.LAWYER].includes(UtilsMixins.computed.currentRole())) {
                count = state.payments.filter(x => x.showRequestButton).length;
                count += state.payments.filter(x => x.status.id == StatusPayment["ปฎิเสธ"].id).length;
            }
            return count;
        },
        anotherConditionDisabledEdit: (state, getters) => {
            let conditions = [];
            if (!state.model.id) {
                return false;
            }

            conditions.push(
                state.model.statusCase.id == StatusCase["ปิดเคส"].id
            );
            conditions.push(
                state.model.statusCase.id == StatusCase["คืนงาน"].id
            );
            conditions.push(state.model.pauseFlag == true);

            let result = false;
            if (conditions.some((x) => x == true)) {
                result = true
            }
            return result
        },
    },
    actions: {
        initData({ state, commit, dispatch }) {
            store.dispatch("caseinfo/getCase").then(res => {
                store.dispatch("caseinfo/getSueItems");
                store.dispatch("caseinfo/getNCBData");
            })

            store.dispatch("caseinfo/getCurrentPaymentStatus");
        },
        saveStorage({ state, commit, dispatch }) {

            manageStorage.updateObject(state.storageCase, state.model);
            manageStorage.updateObject(state.storageSue, { items: state.sueItems });
            manageStorage.updateObject(state.paymentStorage, { items: state.payments });
        },
        clearStorage({ state, commit, dispatch }) {
            manageStorage.updateObject(state.storageCase, null);
            manageStorage.updateObject(state.storageSue, null);
            manageStorage.updateObject(state.paymentStorage, null);

            location.reload();
        },
        getCase({ state, commit, dispatch }) {
            store.dispatch("loading/showLoading");
            let url = `/case/${state.id}`;

            return http
                .get(url)
                .then(res => {
                    state.model = res
                    mockup(state)
                })
                .finally(() => {
                    store.dispatch("loading/hideLoading");
                });


            function mockup(state) {
                // state.model.pauseReason = "ชะลอการฟ้อง"
                // state.model.pauseFlag = true
            }
        },
        getSueItems({ state, commit, dispatch }) {
            let url = `/case/${state.id}/statusesSue`;

            http
                .get(url)
                .then(res => {
                    state.sueItems = res.items;
                })
        },
        getNCBData({ state, commit, dispatch }) {
            let url = `/case/${state.id}/ncb/get`;

            http
                .get(url)
                .then(res => {
                    state.ncbModel = res;
                })
        },
        getCurrentPaymentStatus({ state, commit, dispatch }) {
            let url = `/case/${state.id}/payment/currentStatus`;
            http.get(url).then(res => {
                state.payments = JSON.parse(JSON.stringify(res.items))
                // state.payments = mockupPayment().result.items;
            })
        },
    }
}

function mockupPayment() {
    return {
        "result": {
            "items": [
                {
                    "describe": null,
                    "checkListFiles": [
                        {
                            "id": "2a8d11de-a478-4c7d-8fc0-0ebd6903412b",
                            "text": "คำฟ้อง-ท้ายฟ้อง",
                            "checked": false,
                            "allowEdit": false,
                            "order": 0
                        },
                        {
                            "id": "cb53e06e-fe40-48af-82ad-364ea565958e",
                            "text": "ใบเสร็จค่าส่งคำคู่ความ (นำหมาย)",
                            "checked": false,
                            "allowEdit": true,
                            "order": 1
                        },
                        {
                            "id": "8a32dd4d-863b-4bde-a331-f90db76047d7",
                            "text": "ใบเสร็จค่าขึ้นศาล",
                            "checked": true,
                            "allowEdit": true,
                            "order": 2
                        }
                    ],
                    "latesDate": "07/09/2565 10:10",
                    "showApproveButton": true,
                    "headLawyerPaymentInfo": null,
                    "list": [
                        {
                            "code": "other",
                            "name": null,
                            "amount": "0",
                            "type": "other",
                            "allowEditAmount": false,
                            "order": 0
                        },
                        {
                            "code": "000",
                            "name": "ค่าวิชาชีพ ดำเนินคดี (50 %)",
                            "amount": "900.0000",
                            "type": "+",
                            "allowEditAmount": false,
                            "order": 0
                        },
                        {
                            "code": "001",
                            "name": "ค่าขึ้นศาล",
                            "amount": "1000",
                            "type": "+",
                            "allowEditAmount": true,
                            "order": 0
                        },
                        {
                            "code": "008",
                            "name": "ค่าส่งคำคู่ความ",
                            "amount": "0",
                            "type": "+",
                            "allowEditAmount": true,
                            "order": 1
                        }
                    ],
                    "id": "a1e8b0b5-69a8-45e5-afa1-2faf52fe5e3e",
                    "text": "ดำเนินคดี",
                    "status": {
                        "id": "64ffad7c-1482-4961-97c7-13c301186922",
                        "text": "ยังไม่ได้ทำรายการ"
                    },
                    "showPanel": true,
                    "textRequest": "ขออนุมัติ ค่าวิชาชีพ ส่วน [ดำเนินคดี]",
                    "textCancelRequest": "ยกเลิกการเบิกเงิน",
                    "showCancelRequestButton": false,
                    "showRequestButton": true,
                    "total": null,
                    "totalDecimal": 0,
                    "paidDocNo": null,
                    "paidDate": null,
                    "approveDate": null,
                    "requestDate": null,
                    "paymentTypeId": "d064f979-56af-4fe2-ab39-b3084867bf2d",
                    "paymentPartId": "a1e8b0b5-69a8-45e5-afa1-2faf52fe5e3e"
                }
            ]
        },
        "status": "Success",
        "message": null
    }
}
