import { getUID, urlToPayload, filterString } from './../js/custom';
import { getRelativeStatuses } from './../js/dropdown-service';
import { StatusCase, StatusHeir } from './../js/constants';
import store from './../_store';
import db from './../_helpers/fake-db';

const colletionName = 'relative'
const collectionStatus = 'relative-status'

function create(url, opts) {
    let items = db.getCollection(colletionName);
    let request = opts.body;
    request.id = getUID();

    items.push(request);

    db.updateCollection(colletionName, items)

    return request;
}

function update(url, opts) {
    let items = db.getCollection(colletionName);
    let request = opts.body;
    let index = items.findIndex(x => x.id === request.id)
    items.splice(index, 1, request)

    db.updateCollection(colletionName, items)

    let res = {
        datails: {

        },
        simple: _.cloneDeep(request)

    }
    return res;
}

function get(url, opts) {
    const id = url.split('/').pop();
    let items = db.getCollection(colletionName);
    let res = items.find(x => x.id === id);

    if (!res) {
        res = getRelative();
    }
    return res;
}

function search(url, opts) {
    let items = db.getCollection(colletionName);
    let criteria = urlToPayload(url)

    for (var key in criteria) {
        if (criteria.hasOwnProperty(key)) {
            if (criteria[key]) {
                items = filterString(key, criteria, items)
            }
        }
    }
    for (let i = 0; i < 3; i++) {
        items.push(getRelative())
    }


    return {
        items
    }
}

function remove(url, opts) {
    let id = url.split('/').pop()
    let items = db.getCollection(colletionName);
    let index = items.findIndex(x => x.id === id);
    items.splice(index, 1)
    db.updateCollection(colletionName, items);
}

async function getCurrentStatus(url, opts) {
    let result = await getRelativeStatuses()
    return result.items[0];
}

function updateStatus(url, opts) {
    let req = opts.body;

    let model = _.cloneDeep(store.state.caseinfo.model);
    let { statusCase: currentStatusCase } = model;

    let res = null
    if (_.isEmpty(req)) {
        res = currentStatusCase
    } else {
        if (req.id === StatusHeir['รอสืบทายาท'].id) {
            res = StatusCase['รอสืบทายาท']
        }

        if (req.id === StatusHeir['สืบแล้ว ไม่พบทายาท'].id) {
            res = StatusCase['คืนงาน']
        }

        if (req.id === StatusHeir['สืบแล้ว พบทายาท'].id) {
            res = StatusCase['พร้อมทำ Notice']
        }
    }

    return {
        statusCase: res
    };
}



function getRelative() {
    return {
        id: "61044df7-5ae0-4ab9-38d7-08d7770a491f",
        namePrefix: null,
        namePrefixRemark: null,
        firstName: "ชูชีพ",
        lastName: "ทรัพย์กลิ่น",
        fullName: "ชูชีพ ทรัพย์กลิ่น",
        address: {
            houseNumber: null,
            moo: null,
            trok: null,
            soi: null,
            road: null,
            province: null,
            district: null,
            subDistrict: null,
            telNo: null,
            faxNo: null,
            isActive: false
        }
    };
}


export default {
    search,
    get,
    create,
    update,
    remove,
    getCurrentStatus,
    updateStatus
};


