import Vue from 'vue'
import Router from 'vue-router'
import store from './../_store/index';
import { isloggedIn, getToken } from './../js/authorization';
import { UserRole } from '../js/constants';
import UtilsMixins from './../_mixins/utils-mixins';

// ================ load component ================
const Login = () => import('./../views/global/login');
const Profile = () => import('./../views/global/profile');

const DatePickerDemo = () => import('./../views/datepicker-demo');
const DashboardManagement = () => import('./../views/dashboard/dashboard-management');
const DashboardLawyer = () => import('./../views/dashboard/dashboard-lawyer');
const LawyerCalendar = () => import('./../views/lawyer-calendar');
const Report = () => import('./../views/report');

//case
const SearchCase = () => import('./../views/case/search/search-case');
const UploadCaseMain = () => import('./../views/upload-case/upload-case-main');
const CaseDetails = () => import('./../views/case/case-info');
const RelativeTab = () => import('./../views/case/relative-tab');
const ControlStatus = () => import('./../views/case/control-case-status');


//master
const MasterEmployee = () => import('./../views/master-employee');
const MasterLawyer = () => import('./../views/master-lawyer');
const MasterCourt = () => import('./../views/master-court');
const MasterCustomer = () => import('./../views/master-customer');
const MasterAuctionCompany = () => import('./../views/master-auction-company');

const MasterDocumentType = () => import('./../views/master-document-type');
const MasterProvince = () => import('./../views/master-province');
const MasterDistrict = () => import('./../views/master-district');
const MasterSubDistrict = () => import('./../views/master-subdistrict');
//end master

const BaiberkMaintain = () => import('./../views/baiberk-maintain');
const BaiberkDetails = () => import('./../views/baiberk-details');
const RequestWithdrawList = () => import('./../views/request-withdraw-list');
const Billing = () => import('./../views/billing');
const BillingAEON = () => import('./../views/billing-aeon');

const Remain = () => import('./../views/remain');
const Sanyayom = () => import('./../views/case/sanyayom');
const NCB = () => import('./../views/case/ncb');
const TestCollection = () => import('./../views/test-collection');

Vue.use(Router)


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/searchCase',
      meta: {
        title: "Home", requireAuth: true, permission: [
          UserRole.ADMIN,
          UserRole.MANAGEMENT,
          UserRole.BACK_OFFICE,
          UserRole.LAWYER]
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { fullscreen: true },
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile,
      meta: { title: "Profile", requireAuth: true, permission: ["*"] }
    },
    // {
    //   path: '/test',
    //   name: 'test',
    //   component: Test,
    //   meta: { title: "Test", requireAuth: false, permission: ["*"] }
    // },
    {
      path: '/remain',
      name: 'remain',
      component: Remain,
      meta: { title: "Remain", requireAuth: false, permission: ["*"] }
    },
    {
      path: '/test-collection',
      name: 'test-collection',
      component: TestCollection,
      meta: { title: "Test Collection", requireAuth: false, fullscreen: true, permission: ["*"] }
    },
    {
      path: '/report',
      name: 'report',
      component: Report,
      meta: {
        title: "Report", requireAuth: true, permission: [
          UserRole.ADMIN,
          UserRole.MANAGEMENT,
          UserRole.BACK_OFFICE,
          UserRole.LAWYER]
      }
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      meta: { title: "Dashboard", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.LAWYER] },
      beforeEnter: (to, from, next) => {
        let currentRole = getToken().role;

        if (currentRole === UserRole.LAWYER) {
          next('dashboard-lawyer')
        } else {
          next('dashboard-management')
        }
      }
    },
    {
      path: '/dashboard-management',
      name: 'dashboard-management',
      component: DashboardManagement,
      meta: { title: "Management Dashboard", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/dashboard-lawyer/:lawyerId?/:lawyerName?',
      name: 'dashboard-lawyer',
      props: true,
      component: DashboardLawyer,
      meta: { title: "Lawyer Dashboard", requireAuth: true, permission: [UserRole.ADMIN, UserRole.LAWYER] }
    },
    {
      path: '/lawyer-calendar/:lawyerId?',
      name: 'lawyer-calendar',
      props: true,
      component: LawyerCalendar,
      meta: { title: "lawyer-calendar", requireAuth: true, permission: ["*"] }
    },

    {
      path: '/case-details/:id/:currentTab?',
      name: 'case-details',
      props: true,
      component: CaseDetails,
      meta: { title: "Case", requireAuth: true, permission: ["*"] }
    },
    {
      path: '/searchCase',
      name: 'searchCase',
      component: SearchCase,
      meta: { title: "Search Case", requireAuth: true, permission: ["*"] }
    },
    {
      path: '/upload-case',
      name: 'upload-case',
      component: UploadCaseMain,
      meta: { title: "Upload Cases", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/employee/:id?',
      name: 'employee',
      component: MasterEmployee,
      meta: { title: "Master Employee", requireAuth: true, permission: [UserRole.ADMIN] }
    },
    {
      path: '/lawyer/:id?',
      name: 'lawyer',
      component: MasterLawyer,
      meta: { title: "Master Lawyer", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/court/:id?',
      name: 'court',
      component: MasterCourt,
      meta: { title: "Master Court", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/customer/:id?',
      name: 'customer',
      component: MasterCustomer,
      meta: { title: "Master Customer", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/auction-company/:id?',
      name: 'auction-company',
      component: MasterAuctionCompany,
      meta: { title: "บริษัทขายทอดตลาดรถ", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/documentType/:id?',
      name: 'documentType',
      component: MasterDocumentType,
      meta: { title: "DocumentType", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/datepicker',
      name: 'datepicker',
      component: DatePickerDemo,
      meta: { title: "Date Picker Demo", requireAuth: true, permission: ["*"] }
    },
    {
      path: '/master-province',
      name: 'master-province',
      component: MasterProvince,
      meta: { title: "Master Province", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/master-district',
      name: 'master-district',
      component: MasterDistrict,
      meta: { title: "Master District", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/master-subdistrict',
      name: 'master-subdistrict',
      component: MasterSubDistrict,
      meta: { title: "Master Sub-district", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
    {
      path: '/controlStatus',
      name: 'controlStatus',
      component: ControlStatus,
      meta: { title: "controlStatus", requireAuth: true, permission: ["*"] }
    },
    {
      path: '/relativeTab',
      name: 'relativeTab',
      component: RelativeTab,
      meta: { title: "RelativeTab", requireAuth: true, permission: ["*"] }
    },
    // {
    //   path: '/BulkRequestWithdraw',
    //   name: 'BulkRequestWithdraw',
    //   component: BulkRequestWithdraw,
    //   meta: { title: "ทำเรื่องเบิกเงินค่าวิชาชีพ", requireAuth: true, permission: ["*"] }
    // },
    {
      path: '/request-list',
      name: 'request-list',
      component: RequestWithdrawList,
      meta: {
        title: "รายการร้องขอเบิกเงินทั้งหมด", requireAuth: true, permission: [
          UserRole.ADMIN,
          UserRole.MANAGEMENT,
          UserRole.BACK_OFFICE,
          UserRole.LAWYER
        ]
      }
    },
    {
      path: '/baiberk-maintain',
      name: 'baiberk-maintain',
      component: BaiberkMaintain,
      meta: {
        title: "รายการใบเบิก", requireAuth: true, permission: [
          UserRole.ADMIN,
          UserRole.MANAGEMENT,
          UserRole.BACK_OFFICE,
          UserRole.LAWYER
        ]
      }
    },
    {
      path: '/baiberk-details/:id',
      name: 'baiberk-details',
      component: BaiberkDetails,
      props: true,
      meta: {
        title: "ใบเบิก", requireAuth: true, permission: [
          UserRole.ADMIN,
          UserRole.MANAGEMENT,
          UserRole.BACK_OFFICE,
          UserRole.LAWYER
        ]
      }
    },
    // {
    //   path: '/billing',
    //   name: 'billing',
    //   component: Billing,
    //   props: true,
    //   meta: { title: "รายงานเก็บเงินลูกค้า", requireAuth: true, permission: [UserRole.ADMIN, UserRole.BACK_OFFICE] }
    // },
    {
      path: '/billing-aeon',
      name: 'billing-aeon',
      component: BillingAEON,
      props: true,
      meta: { title: "รายงานเก็บเงินลูกค้า AEON", requireAuth: true, permission: [UserRole.ADMIN, UserRole.MANAGEMENT] }
    },
  ]
})


router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  store.commit('pageinfo/setTitle', title);
  changeDocumentTitle(to);
  // redirect to login page if not logged in and trying to access a restricted page
  if (to.name === 'login') {
    next();
    return;
  }

  let expired = isExpireToken()
  if (expired) {
    UtilsMixins.methods.showAlertError("Token has been expired. Please sign in.")
    next('/login');
    return;
  }

  if (to.meta.requireAuth && !isloggedIn()) {
    UtilsMixins.methods.showAlertError("Please sign in.")
    next('/login');
    return;
  }

  if (UtilsMixins.methods.hasPermission(to.meta.permission)) {
    next()
  } else {
    UtilsMixins.methods.showAlertError("You don't have enough permission to access this page.")
    next("/")
  }
})

function changeDocumentTitle(to) {
  //change title all route except case details page 
  //in case details page have own logic to change title
  if (to.name != 'case-details') {
    document.title = `[${process.env.VUE_APP_SERVER[0]}] ${process.env.VUE_APP_NAME}`;
  }
}

function isExpireToken() {
  let obj = getToken();
  let { expiration } = obj;
  if (new Date(expiration) < new Date()) {
    return true
  } else {
    return false
  }
}

export default router;


