export class Usr {
  static Support = "kawin";
}

export const OtherConfig = {
  defaultPlaceHolder: ".",
};

export const OA = {
  LC: "a173e11d-b2f0-464f-8bb7-c365fdc344a5",
  LC_NAME: "Legal Connect Co., Ltd.",
  SBM: "183e0804-653b-4a26-bead-84174545099e",
  SBM_NAME: "Swift Best Management Co., Ltd.",
  STS: "9d2044b6-7a06-4ea8-8572-a94961ca7faa",
  STS_NAME: "Strong Solutions Co., Ltd.",

};

export const Server = {
  Local: "local",
  Development: "development",
  Production: "production",
};

export const DisplayMode = {
  Development: "development",
  Production: "production",
};

export class DateFormat {
  static diffThaiYear = 543;
  static beautyDateFormat = "DD/MM/YYYY";
  static dateStandardFormat = "YYYY-MM-DD";

  static beautyPeriodFormat = "MM/YYYY";
  static PeriodStandardFormat = "YYYY-MM";
}

export const DATE_PICKER_TYPE = {
  DATE: "date",
  MONTH: "month",
};

export class CategoryFile {
  static CAL_SHEET = "2d2f4ffa-3c74-4ee3-8801-1c157be49af7";
}

//User role
export class UserRole {
  static ADMIN = "ADMIN";
  static LAWYER = "LAWYER";
  static BACK_OFFICE = "BACK_OFFICE";
  static MANAGEMENT = "MANAGEMENT";
}

export const DeptorRelationshipType = {
  samee: "ea64f90c-cbbe-4fee-9c89-400b1f4cdaaa",
  pariya: "22299c1b-6778-401a-a2a6-41983063f931",
};

export const NgodFong = {
  งดฟ้อง: { id: "4d86cbe8-3c6a-4c6f-8f74-acac8eb1ba73", text: "งดฟ้อง" },
  "งดฟ้อง เพราะเข้า SAM": {
    id: "5609a10c-ef00-4870-b53b-84cce361b27e",
    text: "งดฟ้อง เพราะเข้า SAM",
  },
};

export const Chalo = {
  "ชะลอการฟ้อง": {
    id: "7f86251d-34a3-4076-a5d5-a3990eab552a",
    text: "ชะลอการฟ้อง",
  },
  "ชะลอ เพราะเข้า SAM": {
    id: "349cb58f-b15b-4c6e-bd81-5ceac93226a0",
    text: "ชะลอ เพราะเข้า SAM",
  },
};


export const ActionSue = {
  กำหนดวันฟ้อง: 'a63b5120-b5ee-4e4d-b2ba-59e06418e31e',
  ฟ้อง: '67d1ee97-5d16-4818-b4b2-ae3b0319b8eb',
  เลื่อนก่อนฟ้อง: '891b0d72-3cce-4067-8a4a-b6450e38f395',
  พิพากษา: 'fed8c7d2-f598-4b95-af0a-cb7e3271203f',
  ประนีประนอม: 'a46e4fe3-94bd-47e5-9b81-6c8076c11c8e',
  เลื่อนหลังฟ้อง: '7f5e7aac-0eed-4bbf-ba06-234c9bc5e850',
  นำคำบังคับ: '657f5b5b-574d-44c5-a783-1399b40d841f',
  // กำหนดวันงดหมายตั้ง: 'b9f6e24f-27dc-4cd2-8213-c2e33edbef3a',
  พิพากษา_กำหนดวันงดหมายตั้ง: 'b9f6e24f-27dc-4cd2-8213-c2e33edbef3a',
  นำคำบังคับ_กำหนดวันงดหมายตั้ง: 'f5db2a7d-14f2-4440-9810-c6d42f563c13',
  สัญญายอม_กำหนดวันงดหมายตั้ง: '4339e374-a221-4567-8886-d70f65c43932',
  ออกหมายตั้งเจ้าหน้าที่บังคับคดี: '0d79dbdd-3f7e-44b7-bdcc-e8be81b507a4',
  สัญญายอม_ออกหมายตั้งเจ้าหน้าที่บังคับคดี: 'b23e121c-77ec-4dec-b16a-59193892e03c',
  // กำหนดวันงดหมายตั้ง: 'f5db2a7d-14f2-4440-9810-c6d42f563c13',
  งดหมายตั้ง: '1a6ad53f-8827-49d5-865b-0ff740ede9f1',
  แจ้งถอนฟ้อง: '29cbc9c8-d694-4021-9c52-e85bda68e2b8',
  ถอนฟ้อง: 'aa8a69b0-a8ff-4625-96d6-1c6212e8703d',

}


export const StatusCase = {
  New: { id: "59647f1f-3360-4373-ae64-4f292c5e67c6", text: "New" },
  รอสืบทายาท: {
    id: "d64a2620-0186-4693-b67f-83b56a7bd09d",
    text: "รอสืบทายาท",
  },
  "พร้อมทำ Notice": {
    id: "e8d23a6e-8af2-4de4-bcc4-ac297c7b901e",
    text: "พร้อมทำ Notice",
  },
  "ทำ Notice เสร็จแล้ว": {
    id: "b781b37e-15ba-4547-a671-3dadb6750a3f",
    text: "ทำ Notice เสร็จแล้ว",
  },
  รอวันฟ้องจากทนาย: {
    id: "e3826010-2c5a-4b75-a339-1d66cda9cca7",
    text: "รอวันฟ้องจากทนาย",
  },
  เตรียมเอกสารฟ้อง: {
    id: "c603751a-fb0f-46cc-a935-4709a8f96c5b",
    text: "เตรียมเอกสารฟ้อง",
  },
  ทำเอกสารฟ้องเสร็จแล้ว: {
    id: "ae551b03-f550-44c7-87fd-46e5b4bb152b",
    text: "ทำเอกสารฟ้องเสร็จแล้ว",
  },
  ส่งคำฟ้องให้ทนายแล้ว: {
    id: "16475f3c-d246-4ead-a3d0-6a39a3b012f4",
    text: "ส่งคำฟ้องให้ทนายแล้ว",
  },
  ระหว่างยื่นฟ้อง: {
    id: "4bbe32dc-eb3c-4ab6-814f-242cb22686ad",
    text: "ระหว่างยื่นฟ้อง",
  },

  แจ้งถอนฟ้อง: {
    id: "bd6646f6-f116-4420-8e98-d143a0b14b4f",
    text: "แจ้งถอนฟ้อง",
  },
  รอถอนฟ้อง: { id: "7f07810d-128b-4d18-a90d-7952ef88110a", text: "รอถอนฟ้อง" },
  แจ้งงดหมายตั้ง: {
    id: "5c14e809-63a4-4f08-87a1-81481117e3da",
    text: "แจ้งงดหมายตั้ง",
  },
  รอถอนหมายตั้ง: {
    id: "efde9581-79ce-44b4-8ba0-4d11d2f8469e",
    text: "รอถอนหมายตั้ง",
  },
  เข้าSAMก่อนฟ้อง: {
    id: "86a1904a-bd2d-4c1c-8f6a-842154d6c089",
    text: "เข้า SAM ก่อนฟ้อง",
  },
  คืนงาน: { id: "04915df2-87ab-4190-834b-cb4bd4fad50f", text: "คืนงาน" },
  ปิดบัญชี: { id: "c70199d6-2dde-49bc-86a9-95ea8e08aeb2", text: "ปิดบัญชี" },
  ผิดนัดทำยอม: { id: "66312a77-792e-4216-8186-c43f543b6611", text: "ผิดนัดทำยอม" },
  ปิดเคส: {
    id: "e62401e0-1a1a-40f2-941f-3e9e5bd29608",
    text: "ปิดเคส",
    reason: {
      งดฟ้อง: {
        id: "de733898-ef5e-47c4-9883-50d2d920720a",
        text: "งดฟ้อง",
      },
      ถอนฟ้อง: {
        id: "3c79ac1f-5ba2-4d56-9aea-543b0dad0f6a",
        text: "ถอนฟ้อง",
      },
      ออกหมายตั้ง: {
        id: "39c30daf-96c7-4a4c-9f56-27cffe5042c8",
        text: "ออกหมายตั้ง",
      },
      งดหมายตั้ง: {
        id: "bffa384c-3760-4b70-a640-62ab6315cb4d",
        text: "งดหมายตั้ง",
      },
      ปิดเคสทำยอม: {
        id: "7beffaee-8373-4ad0-8fe2-f9d6731aea77",
        text: "ปิดเคสทำยอม",
      },
    },
  },
};

export const StatusSue = {
  kamnodWanFong: {
    option: {
      kamnodWanFong: {
        id: "a63b5120-b5ee-4e4d-b2ba-59e06418e31e",
        text: "กำหนดวันฟ้อง",
      },
    },
  },
  // kamnodWanTonFong: {
  //   option: {
  //     kamnodWanTonFong: {
  //       id: "aa8a69b0-a8ff-4625-96d6-1c6212e8703d",
  //       text: "กำหนดวันถอนฟ้อง",
  //     },
  //   },
  // },
  kamnodWanNgodMaiTang: {
    option: {
      kamnodWanNgodMaiTang: {
        id: "75189c58-8ecf-42e6-bf9e-d64c94cfbcd5",
        text: "กำหนดวันงดหมายตั้ง",
      },
    },
  },
  alreadyNgodMaiTang: {
    option: {
      alreadyNgodMaiTang: {
        id: "1a6ad53f-8827-49d5-865b-0ff740ede9f1",
        text: "งดหมายตั้ง",
      },
    },
  },
  waiting: {
    option: {
      fong: {
        id: "67d1ee97-5d16-4818-b4b2-ae3b0319b8eb",
        text: "ฟ้อง",
      },
      luen: {
        id: "891b0d72-3cce-4067-8a4a-b6450e38f395",
        text: "เลื่อน",
      },
    },
  },
  yuenFong: {
    id: "9df14ee2-2460-4ff8-9d36-4788f82cb379",
    text: "ยื่นฟ้อง รอสืบพยาน (คดีดำ)",
    option: {
      piPakSa: {
        id: "fed8c7d2-f598-4b95-af0a-cb7e3271203f",
        text: "พิพากษา",
      },
      praneePranom: {
        id: "a46e4fe3-94bd-47e5-9b81-6c8076c11c8e",
        text: "ประนีประนอม",
      },
      luen: {
        id: "7f5e7aac-0eed-4bbf-ba06-234c9bc5e850",
        text: "เลื่อน",
      },
      kamnodWanTonFong: {
        id: "29cbc9c8-d694-4021-9c52-e85bda68e2b8",
        text: "กำหนดวันถอนฟ้อง",
      },
      tonFong: {
        id: "AA8A69B0-A8FF-4625-96D6-1C6212E8703D",
        text: "ถอนฟ้อง",
      },
    },
  },
  piPakSa: {
    id: "34c4e1a0-1e35-4949-9229-76b0db88d251",
    text: "พิพากษา (คดีแดง)",
    option: {
      namKamBangKub: {
        id: "657f5b5b-574d-44c5-a783-1399b40d841f",
        text: "นำคำบังคับ",
      },
    },
  },
  namKamBangKub: {
    id: "b912d740-993c-4e72-b6b0-3b570061ab8d",
    text: "นำคำบังคับ",
    option: {
      ookmaiTang: {
        id: "0d79dbdd-3f7e-44b7-bdcc-e8be81b507a4",
        text: "ออกหมายบังคับคดี",
      },
      kamnodWanNgodMaiTang: {
        id: "f5db2a7d-14f2-4440-9810-c6d42f563c13",
        text: "กำหนดวันงดหมายตั้ง",
      }
    },
  },
  ookmaiTang: {
    id: "03d4d8c3-d5b2-47c9-9493-ce36700edbf4",
    text: "ออกหมายตั้งเจ้าหน้าที่บังคับคดี",
  },
  praneePranom: {
    id: "e1a86ff0-bb7c-4e02-a51a-c6f42984e735",
    text: "ทำสัญญาประนีประนอมยอมความ",
    option: {
      ookmaiTang: {
        id: "b23e121c-77ec-4dec-b16a-59193892e03c",
        text: "ออกหมายบังคับคดี",
      },
    },
  },
  tonFong: {
    id: "980e7ffc-ad83-4ea5-ab10-0e8947cbcb0e",
    text: "ถอนฟ้อง",
  },
  ngodMaiTang: {
    id: "f5db2a7d-14f2-4440-9810-c6d42f563c13",
    text: "งดหมายตั้ง",
  },
};

export const StatusHeir = {
  "สืบแล้ว ไม่พบทายาท": {
    id: "da5880bf-7448-4e5d-aa06-1b2676fe9f58",
    text: "สืบแล้ว ไม่พบทายาท",
    parentId: null,
  },
  "สืบแล้ว พบทายาท": {
    id: "a4e56710-69f3-4b52-8700-6dbf65302614",
    text: "สืบแล้ว พบทายาท",
    parentId: null,
  },
  รอสืบทายาท: {
    id: "4aca3509-d9a5-42a3-be0f-7861eafcd731",
    text: "รอสืบทายาท",
    parentId: null,
  },
};

export const StatusPayment = {
  ยังไม่ได้ทำรายการ: {
    id: "64ffad7c-1482-4961-97c7-13c301186922",
    text: "ยังไม่ได้ทำรายการ",
  },
  รออนุมัติ: {
    id: "e79677c0-728a-401c-a46c-67a6fe58c387",
    text: "รออนุมัติ",
  },
  อนุมัติแล้ว: {
    id: "e1846489-56f1-4b01-8622-d7c44a13d948",
    text: "อนุมัติแล้ว",
  },
  ทำเอกสารเบิกแล้ว: {
    id: "64af7b9f-4755-4327-a95b-48aaa245743d",
    text: "ทำเอกสารเบิกแล้ว",
  },
  จ่ายเงินแล้ว: {
    id: "1c7aabc1-1fbd-4061-87aa-07956af12603",
    text: "จ่ายเงินแล้ว",
  },
  ปฎิเสธ: {
    id: "6ff0d5b5-a632-4981-aeef-4856447fd4d7",
    text: "ปฎิเสธ",
  },
};

export const PaymentPart = {
  advance: "ba2bc7b8-2bb4-4c7a-85af-8627868a6252",
  fong: "a1e8b0b5-69a8-45e5-afa1-2faf52fe5e3e",
  namKamBangKub: "64d29041-210c-4074-ac72-64d6cb56e9f3",
  praneePranom: "a76b7036-7fd1-4d40-a788-22ac844742dc",
  ookmaiTang: "70f8cca7-d049-43a0-a9f3-56b60516128d",
  tonFong: "29e11467-1039-4cdc-9187-7c163df42af6",
  yomkwamJaiNgernKrob: "c32603b6-e944-4264-96ef-bd117ba7ddaf",
};


export const PaymentType = {
  ค่าวิชาชีพ: {
    id: "d064f979-56af-4fe2-ab39-b3084867bf2d",
    text: "ค่าวิชาชีพ",
  },
  advance: {
    id: "b12021e8-9f32-43e1-94a2-f3d4b3cc27f1",
    text: "ค่า Advance",
  },
  ค่าจัดการทนาย: {
    id: "449defb9-687d-4c92-95b2-cbc09c3cf9ad",
    text: "ค่าจัดการทนาย",
  },
};




export const StatusFile = {
  FAIL: "fail",
  PENDING: "pending",
  UPLOADED: "uploaded",
};

export const InputType = {
  dropdown: "dropdown",
  dropdownMultiple: "dropdown-multiple",
  text: "text",
  number: "number",
  checkBox: "checkbox",
  radio: "radio",
  password: "password",
  textArea: "text-area",
  datePicker: DATE_PICKER_TYPE.DATE,
  monthPicker: DATE_PICKER_TYPE.MONTH,
  timePicker: "time-picker",
  section: "section",
  offset: "offset",
  label: "label",
  button: "button",
  rowSetting: "row-setting",
  begin_end_date: "begin_end_date",
  begin_end_period: "begin_end_period",
  lineConnect: "line-connect",
  dropdownRelate: "dropdown-relate",

  tableLawyerCourt: "table-lawyer-court",
  courtCovarageArea: "court-covarage-area",
  courtCustomer: "court-customer",
  address: "address-block",
  refinanceList: "refinace-list",
  pipaksaList: "pipaksa-list",
  namkamList: "namkam-list",
};

export const WrapType = {
  alertSuccess: "alert-success",
};

export class MasterMode {
  static add = "add";
  static edit = "edit";
}

export class Customer {
  static AIRA_ID = "983fa2e3-9d4c-4a42-bc18-b4de0194805b";
  static AEON_ID = "39b0a7f5-c033-44c6-8191-1fc570013048";
  static RABBIT_ID = "b696fc67-a616-443c-bb1c-1b8560e84ec1";
}

export class InstallmentPayment {
  static FirstPayment = "c759da6d-d9d4-46bd-a6a3-7455e46b98dd";
  static LastPayment = "53e47ab2-a4d8-4088-8083-91637dce3f37";
}

export class ContractType {
  static AIRA = "08D6EB55-237D-4B49-8D43-17E6AF19CE12";
  static AEON_LOAN = "3A2DC16D-1EF1-4B00-92D6-5BBAD65DD94D";
  static AEON_CARD = "E3F39333-0B26-4FC2-AA17-D9F6913337C0";
  static AEON_LEASING = "25970e46-fe6c-4205-88ad-eb0d207097f3";
  static AEON_LEASING = "25970e46-fe6c-4205-88ad-eb0d207097f3";
  static RABBIT_CASH = "c20e2a4b-24fe-4d84-9b2c-ef2d8fba17f9";
  static RABBIT_INSTALLMENT = "7f8d67ad-ab80-43bf-a993-f03f2b5e33f0";
  static RABBIT_BENEFIT = "1eacec9e-c8cd-49bf-975e-6528717b9d82";
  static RABBIT_BENEFIT_SPECIAL = "f64c02f7-e5fa-45f3-941b-70ed80f03ad6";


}

export class ContractCondition {
  static AEONCard_Creditcard_Normal = "c43690e3-e251-47b4-887c-c21632890a72";
  static AEONCard_Creditcard_AutoSwitch =
    "a23c4b82-d741-4e2a-9ac8-ff12fdac64cc";
  static AEONCard_Creditcard_AutoRenew = "8b0a97e0-1da6-431a-8500-2690da16605a";

  static AEONLoan_YourCash_Normal = "d1ac2898-ee18-4597-82fe-e0a64d55b26a";
  static AEONLoan_YourCash_AutoRenew = "5f4335d9-418d-49ea-af9c-ebd9a2e565d1";
  static AEONLoan_YourCash_AutoSwith = "272ccd62-27bc-4070-91ea-f0f41c65a722";

  static AEONLoan_General_Normal = "ec387084-e2cd-4969-a63a-22d1bfd0c536";
  static AEONLoan_General_PranomNee = "0325601f-9883-490a-a830-e82303ab8e2e";

  static AEONLoan_Versatile = "9f48e2c3-0791-4883-9627-6a4061772e69";

  static AEONLeasing_VehicleType_MotoCycle =
    "72bbe727-8fa8-4d7f-a1f5-06c52ffb321d";
  static AEONLeasing_VehicleType_Car = "02c2bd19-087a-454f-8e68-48f948a246f7";
  static AEONLeasing_NoticeType_1 = "25993cdd-f187-48f5-a743-aa340ed3af38"; //เรียกรถคืน
  static AEONLeasing_NoticeType_2 = "e44a30f0-0f62-4d4c-ad5f-aab11259b006"; //เรียกส่วนต่าง (ขายขาดทุน)
}

export const DialogSize = {
  small: "small",
  medium: "medium",
  fullScreen: "full-screen",
};

export class NamePrefix {
  static otherId = "a8c35b9e-b3dd-4ba5-8871-be08c492d323";
}

export const CollapseMode = {
  collapse: "collapse",
  expand: "expand",
};

export const PipaksaResultType = {
  single: "0395b541-3def-4633-a08e-9c4fdfeb3329",
  multiple: "1a99f699-3462-4e14-bfc3-6e496c624f2f",
};

export const RevertType = {
  ยกเลิกจ่ายเงินใบเบิก: {
    id: "906b5299-293b-4f91-b7d8-77385f9dba32",
  },
  ยกเลิกใบเบิก: {
    id: "fdd0452f-831c-421b-bc86-893972e45ab5",
  },
  ยกเลิกอนุมัติ: {
    id: "bc8ba5b8-33e4-439a-a810-d1e0b0b4ada3",
  },
  ลบสถานะคดี: {
    id: "633fcc72-d9f1-44a9-b556-ca834923688c",
  },
};
