
export default {
    watch: {
        parentVal: {
            handler(newVal, oldVal) {
                this.parentChanged(newVal);
            },
            immediate: false
        },
    },
    methods: {
        getParentId(parent) {
            let parentId = null;

            if (parent && parent.id) {
                parentId = parent.id;
            }

            return parentId;
        },
        parentChanged(parent) {
            let parentId = this.getParentId(parent);
            let existingParentId = _.get(this.innerValue, this.parentField);
            if (!(existingParentId === parentId)) {
                this.clearExisting();
            }

            this.fetchRelateItems(parent);
        },
        clearExisting() {
            this.innerValue = null;
            this.items = [];
        },
        fetchRelateItems(parent) {
            let parentId = this.getParentId(parent);

            if (this.input.parent && !parentId) {
                return;
            }

            let url = this.input.data.url + (parentId || "");

            this.$http.get(url).then(res => {
                this.items = res.items;
            });
        }
    },
    computed: {
        parentVal() {
            return _.get(this.model, this.input.parent);
        },
        parentField() {
            return this.input.parentField || "parentId";
        },
    }
}