import { getDropdownItem } from './../js/custom';
import db from './../_helpers/fake-db';
import { StatusCase } from './../js/constants'
import lawyers from './../_mock-data/dropdown/lawyer'


function getOpenCase() {
    let items = [
        {
            "customer": {
                "id": "39b0a7f5-c033-44c6-8191-1fc570013048",
                "text": "AEON"
            }
        },
        {
            "customer": {
                "id": "983fa2e3-9d4c-4a42-bc18-b4de0194805b",
                "text": "AIRA"
            }
        }
    ]

    items.forEach(item => {
        item.count = Math.floor(Math.random() * 2000) + 500
    })

    return returnModel(items)
}

function getCaseLatestMonth() {
    let items = [
        {
            "customer": {
                "id": "39b0a7f5-c033-44c6-8191-1fc570013048",
                "text": "AEON"
            },
        },
        {
            "customer": {
                "id": "983fa2e3-9d4c-4a42-bc18-b4de0194805b",
                "text": "AIRA"
            },
        }
    ]

    items.forEach(item => {
        item.count = Math.floor(Math.random() * 2000) + 500
    })


    return returnModel(items)

}

function getTwelveMonthCompareOpen() {
    let items = getTwelveMonthBackwards();

    items.forEach(item => {
        item.open = Math.floor(Math.random() * 1000) + 1
        item.closed = Math.floor(Math.random() * 1000) + 1
        item.total = item.open + item.closed;

        item.percentage = item.closed / item.total * 100
    })


    return returnModel(items)
}

function getProportionOpenCase() {
    let items = []
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['New']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['รอสืบทายาท']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['พร้อมทำ Notice']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['ทำ Notice เสร็จแล้ว']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['รอวันฟ้องจากทนาย']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['เตรียมเอกสารฟ้อง']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['ทำเอกสารฟ้องเสร็จแล้ว']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['ส่งคำฟ้องให้ทนายแล้ว']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['ระหว่างยื่นฟ้อง']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['แจ้งถอนฟ้อง']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['รอถอนฟ้อง']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['แจ้งงดหมายตั้ง']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['รอถอนหมายตั้ง']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['แจ้งงดหมายตั้ง']) }, { count: 100 }));
    items.push(Object.assign({}, { statusCase: getDropdownItem(StatusCase['รอถอนหมายตั้ง']) }, { count: 100 }));

    return returnModel(items)
}

function getTwelveMonthBackwards() {
    return [
        {
            "name": "กุมภาพันธ์ 2562",
        },
        {
            "name": "มีนาคม 2562",
        },
        {
            "name": "เมษายน 2562",
        },
        {
            "name": "พฤษภาคม 2562",
        },
        {
            "name": "มิถุนายน 2562",
        },
        {
            "name": "กรกฎาคม 2562",
        },
        {
            "name": "สิงหาคม 2562",
        },
        {
            "name": "กันยายน 2562",
        },
        {
            "name": "ตุลาคม 2562",
        },
        {
            "name": "พฤศจิกายน 2562",
        },
        {
            "name": "ธันวาคม 2562",
        },
        {
            "name": "มกราคม 2563",
        }
    ]

}
function getTrendCaseNumber() {
    let items = getTwelveMonthBackwards();
    items.forEach(item => {
        item.count = Math.floor(Math.random() * 100) + 1
    })

    return returnModel(items)
}

function getLawyerPerformance() {
    let items = lawyers.map(lawyer => {
        return _.pick(lawyer, ['id', 'firstName', 'lastName', 'namePrefix', 'namePrefixRemark'])
    })

    items.forEach(item => {
        item.performancePercentage = Math.floor(Math.random() * 100) + 1
    })

    items = _.orderBy(items, ['performancePercentage'], ['asc'])

    return returnModel(items)
}

function getLawyerPerformanceTopTen() {
    let items = getLawyerPerformance().items;

    items = items.slice(0, 10)

    return returnModel(items)
}


function lawyer_getCaseNumberLatesMonth() {
    return {
        count: 2340
    }
}

function lawyer_getOpenCaseNumber() {
    return {
        count: 223
    }
}

function lawyer_getSuccessRateBackwards() {
    let items = []
    let months = [1, 3, 6, 12];
    months.forEach(number => {
        items.push({
            name: `ย้อนหลัง ${number} เดือน`,
            successRate: Math.floor(Math.random() * 100) + 1,
        })
    })

    return returnModel(items)
}

function getTrendOpenCaseAccumulate() {
    let items = getTwelveMonthBackwards();
    items.forEach(item => {
        item.count = Math.floor(Math.random() * 100) + 1
    })
    return returnModel(items)

}



export default {
    getOpenCase,
    getCaseLatestMonth,
    getTwelveMonthCompareOpen,
    getProportionOpenCase,
    getTrendOpenCaseAccumulate,

    getTrendCaseNumber,
    getLawyerPerformance,
    getLawyerPerformanceTopTen,
    lawyer_getCaseNumberLatesMonth,
    lawyer_getOpenCaseNumber,
    lawyer_getSuccessRateBackwards
};

function returnModel(items) {
    return {
        items,
        record: items.length
    }
}