
import { getUID } from '../js/custom'

function checkVerified() {
    return true;
}

function generateToken() {
    return getUID();
}



export default {
    checkVerified,
    generateToken,
};

