import courts from './court';
import customers from './customer';
import documentTypes from './document-type';
import lawyers from './lawyer';
import namePrefixs from './name-prefix';


export default {
    courts,
    customers,
    documentTypes,
    lawyers,
    namePrefixs,
}


