import { getUID, urlToPayload, filterString } from './../js/custom';
import * as constants from './../js/constants';
import mock from './../_mock-data/dropdown';

import { fakeAbbr } from './../_helpers/fake-url';




function getCourts() {
    return {
        items: mock.courts
    }
}

function getLawyers() {
    return {
        items: mock.lawyers
    }
}

function getCustomers() {
    return {
        items: mock.customers
    }
}

function getDocumentTypes() {
    return {
        items: mock.documentTypes
    }
}

function getNamePrefixs() {
    return {
        items: mock.namePrefixs
    }
}

function getStatuses() {
    let obj = _.cloneDeep(constants.StatusSue);
    let arr = _.toArray(obj)
    let items = arr.map(x => _.pick(x, ['id', 'text']))
    return {
        items
    }
}

function getCourtAreas() {
    let items = [];
    items.push(...data1())
    items.push(...data2())
    return { items }

    function data1() {
        return [
            { id: "111111,*", text: "อำเภอ 111111,*" },
            { id: "111111,01", text: "อำเภอ 111111,01" },
            { id: "111111,02", text: "อำเภอ 111111,02" },
            { id: "111111,03", text: "อำเภอ 111111,03" },
            { id: "111111,04", text: "อำเภอ 111111,04" },
            { id: "111111,05", text: "อำเภอ 111111,05" }
        ];
    }

    function data2() {
        return [
            { id: "222222,*", text: "อำเภอ 222222,*" },
            { id: "222222,01", text: "อำเภอ 222222,01" },
            { id: "222222,02", text: "อำเภอ 222222,02" },
            { id: "222222,03", text: "อำเภอ 222222,03" },
            { id: "222222,04", text: "อำเภอ 222222,04" },
            { id: "222222,05", text: "อำเภอ 222222,05" }
        ];
    }
}

function getSueReasonToLuen() {
    let items = []
    items.push({
        id: "22effb01-f4f9-404f-8949-d3d0175fd41b",
        text: "เหตุผลที่เลื่อน 1"
    })
    items.push({
        id: "8e2dbb5b-69a0-4463-8ed3-d141e8b4bbb9",
        text: "เหตุผลที่เลื่อน 2"
    })
    items.push({
        id: "a7452bab-4ec2-4332-a1b4-301077402b19",
        text: "เหตุผลที่เลื่อน 3"
    })
    items.push({
        id: "a1e293f3-5801-450c-be9a-8cd05c07e21d",
        text: "เหตุผลที่เลื่อน 4"
    })


    return {
        items
    }
}




function filterByParentId(items, url) {
    let arr = url.split('=');
    let parentId = arr[1];
    if (parentId) {
        items = items.filter(x => x.parentId === parentId);
    }
    return items;
}




function endpoint(url, opts) {
    url = url.toLowerCase();
    let method = opts.method;

    if (url.includes(fakeAbbr.dropdown.master_court.toLowerCase())) {
        return getCourts()
    }
    else if (url.includes(fakeAbbr.dropdown.master_layer.toLowerCase())) {
        return getLawyers()
    }
    else if (url.includes(fakeAbbr.dropdown.master_customer.toLowerCase())) {
        return getCustomers()
    }
    else if (url.includes(fakeAbbr.dropdown.master_documentType.toLowerCase())) {
        return getDocumentTypes();
    }
    else if (url.includes(fakeAbbr.dropdown.master_namePrefix.toLowerCase())) {
        return getNamePrefixs();
    }
    else if (url.includes(fakeAbbr.dropdown.master_status.toLowerCase())) {
        return getStatuses();
    }
    else if (url.includes(fakeAbbr.dropdown.court_area.toLowerCase())) {
        return getCourtAreas();
    }
    else if (url.includes(fakeAbbr.dropdown.sue_reasonToLuean.toLowerCase())) {
        return getSueReasonToLuen();
    }

}

export default { endpoint };
