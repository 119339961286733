<template>
  <div>
    <v-row>
      <v-col offset="1" cols="10">
        <show-model :text="innerValue"></show-model>
        <v-simple-table dense>
          <thead>
            <tr class="teal lighten-5 ">
              <th colspan="100%">
                <ValidationObserver ref="observer" v-slot="{ valid }">
                  <v-row class="pt-2 pl-2">
                    <v-col cols="12" class="d-inline-block">
                      <render-input
                        ref="ddl"
                        :input="fieldConfig.ddl"
                        v-model="criteria.customer"
                        :autoFetchItems="false"
                        class="d-inline-block"
                      ></render-input>
                      <render-input
                        :input="fieldConfig.lawyerLegalFees"
                        v-model="criteria.lawyerLegalFees"
                        class="d-inline-block ml-3"
                        :disabled="!criteria.customer"
                      ></render-input>

                      <v-chip
                        class="ml-2 mb-3"
                        color="primary"
                        :disabled="!(enabledButtonAdd && valid)"
                        text
                        @click="clickAdd()"
                      >
                        <v-icon left>add_circle</v-icon>Add Data
                      </v-chip>
                    </v-col>
                  </v-row>
                </ValidationObserver>
              </th>
            </tr>
            <tr>
              <th class="text-center" width="10%">No.</th>
              <th class="text-center" width="40%">ลูกค้า</th>
              <th class="text-center" width="30%">ค่าวิชาชีพ</th>
              <th class="text-center" width="20%">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in sortingItems" :key="index">
              <td class="text-center">{{ index + 1 }}</td>
              <td>{{ item.customer.text }}</td>
              <td class="text-right">
                {{ item.lawyerLegalFees | currency(0) }}
              </td>
              <td class="text-center">
                <v-icon color="error" v-if="canRemove" @click="removeData(item)"
                  >delete</v-icon
                >
              </td>
            </tr>
            <tr v-show="innerValue.length === 0">
              <td colspan="100%" class="text-center">No Result. Please add</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getFakeUrl, fakeAbbr } from "./../_helpers/fake-url";
import { InputType } from "./../js/constants";
import { getCustomers } from "./../js/dropdown-service";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: true,
    },
  },
  data() {
    return {
      allOptions: [],
      criteria: {
        customer: null,
        lawyerLegalFees: null,
      },
    };
  },
  watch: {
    filteredOption: {
      handler(newVal) {
        this.$refs.ddl.setItems(newVal);
      },
    },
  },
  created() {
    this.getOptions();
  },
  mounted() {},
  methods: {
    getOptions() {
      getCustomers().then((res) => {
        this.allOptions = res.items;
      });
    },
    clickAdd() {
      this.addItems();
      this.resetCriteria();
    },
    resetCriteria() {
      this.criteria = {
        customer: null,
        lawyerLegalFees: null,
      };
    },
    removeData(item) {
      let index = this.innerValue.findIndex(
        (x) => x.customer.id === item.customer.id
      );
      this.innerValue.splice(index, 1);
    },
    addItems() {
      let existing = this.innerValue.find(
        (x) => x.customer.id == this.criteria.customer.id
      );
      if (!existing) {
        this.innerValue.push(_.cloneDeep(this.criteria));
      }
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    sortingItems() {
      let value = this.innerValue || [];
      return _.sortBy(value, ["customer.text"]);
    },
    enabledButtonAdd() {
      let result = false;
      if (this.criteria.customer && this.criteria.lawyerLegalFees) {
        result = true;
      }
      return result;
    },
    filteredOption() {
      let filtered = [];

      let customerExist = this.innerValue.map((x) => x.customer);
      this.allOptions.forEach((item) => {
        if (customerExist.findIndex((x) => x.id === item.id) < 0) {
          filtered.push(item);
        }
      });

      filtered = _.orderBy(filtered, ["text"], ["asc"]);
      return filtered;
    },
    canRemove() {
      if (!this.model.id) {
        return true;
      }
      return this.model.permission.canRemove;
    },
    fieldConfig() {
      return {
        ddl: {
          name: "ddl",
          text: "ลูกค้า",
          type: InputType.dropdown,
        },
        lawyerLegalFees: {
          name: "lawyerLegalFees",
          text: "ค่าวิชาชีพ",
          type: InputType.text,
          rule: {
            number: true,
          },
        },
      };
    },
  },
};
</script>

<style scoped>
tr td {
  padding-top: 10px;
}
</style>
