import { getCurrentTime, getDropdownItem, getUID } from '../js/custom';
import { StatusPayment, PaymentPart, UserRole } from '../js/constants';
import manageStorage from '../js/manage-localstorage';
import store from '../_store';
import { getStatusSue } from '../js/dropdown-service'
import UtilsMixins from './../_mixins/utils-mixins';




import db from '../_helpers/fake-db';


export default {
    getCurrentStatus2,
    requestPayment2,
    approve2,
    getChecklist,
    toggleChecklist,
    getFile,
    uploadFile,
    initialAdvance,
    cancelApprove
};


function getChecklist() {
    let items = [
        {
            id: "file 1",
            text: "file 1",
            checked: true
        },
        {
            id: "file 2",
            text: "file 2",
            checked: false
        }
    ]

    return {
        items
    }
}


function toggleChecklist() {

}



function getFile() {
    return {
        items: []
    }
}



function uploadFile() {
    let model = {
        "caseId": "82c22cd3-5de4-4b05-93d4-08d7a091bd1a",
        "categoryId": "ef4ae1ef-c8d8-46b5-ae13-890d0fdec484",
        "fileName": "login-bg (1).jpg",
        "fileUrl": "/case/attachment/defe0ec2-61d2-4717-9c73-08d7a592ce3e",
        "status": "uploaded",
        "contentType": "image/jpeg",
        "id": "defe0ec2-61d2-4717-9c73-08d7a592ce3e",
        "changedDate": null,
        "changedBy": null
    }

    return model
}

function cancelApprove() {
    return getCurrentStatus2();
}


function getObj() {
    let obj =
    {
        id: "a1e8b0b5-69a8-45e5-afa1-2faf52fe5e3e",
        text: "1. ส่วนหลังจากเกิดหมายเลขคดีดำ",
        describe: "",
        order: 1,
        checkListFiles: [
            {
                id: "",
                text: "file 1",
                checked: true
            }
        ],
        status: {
            id: "",
            text: ""
        },
        latesDate: "",
        showPanel: true,
        textRequest: "เบิกส่วนหลังจากเกิดหมายเลขคดีดำ",
        textCancelRequest: "ยกเลิกการเบิกเงิน",
        showCancelRequestButton: true,
        showRequestButton: true,
        showApproveButton: true,
        showCancelApprove: true,
        list: [
            {
                code: "001",
                name: "sdffsdf",
                amount: 123,
                type: "+"
            },
            {
                code: "002",
                name: "sdffsdf",
                amount: 123,
                type: "+"
            },
            {
                code: "003",
                name: "sdffsdf",
                amount: -123,
                type: "-"
            },
            {
                code: "other",
                name: "ค่าเดินทาง",
                amount: 0,
                type: "other"
            }
        ],
    }

    return obj
}


function conditionShowApprove(obj) {
    let permission = [UserRole.ADMIN, UserRole.BACK_OFFICE];
    if (!UtilsMixins.methods.hasPermission(permission)) {
        return false;
    }

    if (obj.status && obj.status.id === StatusPayment["รออนุมัติ"].id) {
        return true;
    }

    return false;
}

function initialAdvance() {
    let model = {
        id: PaymentPart.advance,
        text: "เบิกค่าใช้จ่ายล่วงหน้า",
        status: StatusPayment['ยังไม่ได้ทำรายการ'],
        list: [],
        showPanel: true,
        textRequest: "เบิกส่วนหลังจากเกิดหมายเลขคดีดำ",
        textCancelRequest: "ยกเลิกการเบิกเงิน",
        showRequestButton: true,
        showCancelRequestButton: true,
        showCancelApprove: true
    }

    let records = Math.floor(Math.random() * 6) + 1;
    records = 5;

    for (let i = 0; i < records; i++) {
        let item = {}
        item.code = i.toString().padStart(3, "0")
        item.name = `advance ${i + 1}`;
        item.amount = 13242;
        model.list.push(item);
    }
    return model;
}


//todo: change model to use this instead
function getCurrentStatus2() {
    let items = []
    items.push(initialAdvance())

    items.push(fong())
    items.push(namKamBangKub())
    items.push(praneePranom())
    items.push(ookmaiTang())
    items.push(tonFong())
    items.push(yomkwamJaiNgernKrob())

    return {
        items
    }


    function fong() {
        let obj = getObj();
        obj.id = PaymentPart.fong;
        obj.text = "1. ส่วนหลังจากเกิดหมายเลขคดีดำ"
        obj.status = StatusPayment['ยังไม่ได้ทำรายการ']
        obj.describe = `เงื่อนไข หลังจากเกิดหมายเลขคดีดำ`
        obj.textRequest = "เบิกส่วนหลังจากเกิดหมายเลขคดีดำ"
        obj.showPanel = conditionShowPanel();
        obj.showRequestButton = conditionRequestPayment();
        obj.showApproveButton = conditionShowApprove(obj);
        obj.latesDate = getCurrentTime();

        return obj;

        function conditionShowPanel() {
            return true;
        }

        function conditionRequestPayment() {
            return true;
        }
    }




    function namKamBangKub() {
        let obj = getObj();
        obj.id = PaymentPart.namKamBangKub;
        obj.text = "2. ส่วนพิพากษาแล้ว"
        obj.status = StatusPayment['ยังไม่ได้ทำรายการ']
        obj.describe = `เงื่อนไข พิพากษาแล้ว`
        obj.textRequest = "เบิกเงินส่วนพิพากษาแล้ว"
        obj.showPanel = conditionShowPanel();
        obj.showRequestButton = conditionRequestPayment();
        obj.showApproveButton = conditionShowApprove(obj);
        obj.latesDate = getCurrentTime();

        return obj;

        function conditionShowPanel() {
            return true;
        }

        function conditionRequestPayment() {
            return true;
        }
    }

    function praneePranom() {
        let obj = getObj();
        obj.id = PaymentPart.praneePranom;
        obj.text = "3. เบิกเงินส่วนประนอมยอมความ"
        obj.status = StatusPayment['ยังไม่ได้ทำรายการ']
        obj.describe = `เงื่อนไข หลังจากเกิดหมายเลขคดีดำ`
        obj.textRequest = "เบิกเงินส่วนประนอมยอมความ"
        obj.showPanel = conditionShowPanel();
        obj.showRequestButton = conditionRequestPayment();
        obj.showApproveButton = conditionShowApprove(obj);
        obj.latesDate = getCurrentTime();

        return obj;

        function conditionShowPanel() {
            return true;
        }

        function conditionRequestPayment() {
            return true;
        }
    }


    function ookmaiTang() {
        let obj = getObj();
        obj.id = PaymentPart.ookmaiTang;
        obj.text = "4. ส่วนออกหมายตั้งแล้ว"
        obj.status = StatusPayment['ยังไม่ได้ทำรายการ']
        obj.describe = `เงื่อนไข ออกหมายตั้งแล้ว`
        obj.textRequest = "เบิกเงินส่วนออกหมายตั้งแล้ว"
        obj.showPanel = conditionShowPanel();
        obj.showRequestButton = conditionRequestPayment();
        obj.showApproveButton = conditionShowApprove(obj);
        obj.latesDate = getCurrentTime();

        return obj;

        function conditionShowPanel() {
            return true;
        }

        function conditionRequestPayment() {
            return true;
        }
    }


    function tonFong() {
        let obj = getObj();
        obj.id = PaymentPart.tonFong;
        obj.text = "ถอนฟ้อง"
        obj.status = StatusPayment['ยังไม่ได้ทำรายการ']
        obj.describe = `เงื่อนไข ถอนฟ้อง`
        obj.textRequest = "เบิกเงินส่วนถอนฟ้อง"
        obj.showPanel = conditionShowPanel();
        obj.showRequestButton = conditionRequestPayment();
        obj.showApproveButton = conditionShowApprove(obj);
        obj.latesDate = getCurrentTime();

        return obj;

        function conditionShowPanel() {
            return true;
        }

        function conditionRequestPayment() {
            return true;
        }
    }

    function yomkwamJaiNgernKrob() {
        let obj = getObj();
        obj.id = PaymentPart.yomkwamJaiNgernKrob;
        obj.text = "ปิดเคสทำยอม"
        obj.status = StatusPayment['ยังไม่ได้ทำรายการ']
        obj.describe = `เงื่อนไข ปิดเคสทำยอม`
        obj.textRequest = "เบิกเงินส่วนปิดเคสทำยอม"
        obj.showPanel = conditionShowPanel();
        obj.showRequestButton = conditionRequestPayment();
        obj.showApproveButton = conditionShowApprove(obj);
        obj.latesDate = getCurrentTime();

        return obj;

        function conditionShowPanel() {
            return true;
        }

        function conditionRequestPayment() {
            return true;
        }
    }
}



function requestPayment2(url, opts) {
    let payment = getCurrentStatus2()

    let body = opts.body

    let index = payment.items.findIndex(x => x.id === body.id)

    let item = payment.items[index];
    item.status = getDropdownItem(StatusPayment['รออนุมัติ']);
    item.latestDate = getCurrentTime();
    item.showRequestButton = false;
    item.showApproveButton = conditionShowApprove(item);

    payment.items.splice(index, 1, item)
    return payment;
}

function approve2(url, opts) {
    let payment = getCurrentStatus2()

    let body = opts.body

    let index = payment.items.findIndex(x => x.id === body.id)

    let item = payment.items[index];
    item.status = getDropdownItem(StatusPayment['อนุมัติแล้ว']);
    item.latestDate = getCurrentTime();
    item.showRequestButton = false;
    item.showApproveButton = false;

    payment.items.splice(index, 1, item)
    return payment;
}
