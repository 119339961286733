function getObject(name) {
    //default collection
    if (!localStorage.hasOwnProperty(name) || !localStorage.getItem(name)) {
        localStorage.setItem(name, '{}')
    }

    return JSON.parse(localStorage.getItem(name))
}

function updateObject(name, newObj) {
    localStorage.setItem(name, JSON.stringify(newObj))
}


export default {
    getObject,
    updateObject
}