<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        :fullscreen="fullscreen"
        :width="calSize"
        hide-overlay
        transition="dialog-bottom-transition"
        @keydown.esc="hideDialog"
        :key="dialog"
      >
        <v-card>
          <!-- overlay waiting create form -->
          <v-overlay :value="!ready"></v-overlay>
          <!-- overlay waiting create form -->
          <ValidationObserver
            ref="observer"
            v-slot="{ valid }"
          >
            <v-toolbar light>
              <v-btn
                icon
                light
                @click="hideDialog"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{title}}</v-toolbar-title>
              <div class="flex-grow-1"></div>
              <v-toolbar-items v-if="showSubmitButton">
                <v-btn
                  v-if="canSubmit"
                  light
                  text
                  :disabled="!valid"
                  large
                  @click="submit"
                >Submit</v-btn>
              </v-toolbar-items>
            </v-toolbar>

            <v-lazy>
              <v-card>
                <v-card-text>
                  <custom-copy-value
                    :item="model"
                    :inline="true"
                    class="mb-2"
                  ></custom-copy-value>

                  <slot></slot>
                </v-card-text>
                <v-card-actions>
                  <slot
                    name="action"
                    :valid="valid"
                  ></slot>
                </v-card-actions>
              </v-card>
            </v-lazy>
          </ValidationObserver>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>


<script>
import * as custom from "./../../js/custom";
import { DialogSize } from "./../../js/constants";

export default {
  props: {
    title: String,
    canSubmit: {
      type: Boolean,
      default: true,
    },
    showSubmitButton: {
      type: Boolean,
      default: true,
    },
    width: Number,
    size: String,
    model: Object,
  },
  data() {
    return {
      dialog: false,
      ready: false,
    };
  },
  watch: {
    dialog(newVal, oldVal) {
      if (newVal) {
        requestAnimationFrame(() => {
          this.$refs.observer.reset();
        });
      }
      this.hiddenHtmlScrollbar(newVal);
    },
    model: {
      handler(newVal) {
        this.$nextTick(() => {
          //workaround Fixed vee-validate invalid data validation. in spite of everything being correct
          //run this first time model set
          this.$refs.observer.reset();
        });
      },
    },
  },
  created() {},
  methods: {
    showLoading() {
      this.ready = false;
    },
    hideLoading() {
      this.ready = true;
    },
    showDialog() {
      this.dialog = true;
      this.$emit("show-dialog");
    },
    hideDialog() {
      this.dialog = false;
      this.$emit("close-dialog");
      this.$parent.$emit("close-dialog");
    },
    submit() {
      this.$emit("submit");
    },
  },
  computed: {
    fullscreen() {
      if (this.width) {
        return false;
      }

      if (this.size === DialogSize.fullScreen) {
        return true;
      }

      return false;
    },
    calSize() {
      if (this.width) {
        return this.width;
      }

      if (!this.size) {
        return null;
      }

      switch (this.size) {
        case DialogSize.small:
          return 500;
        case DialogSize.medium:
          return 1000;
      }
    },
  },
};
</script>


