import db from '../_helpers/fake-db';
import { StatusPayment, PaymentPart } from '../js/constants';
import { getCurrentTime, getDropdownItem, getUID } from '../js/custom';

import fakePaymentIncase from './fake-payment-in-case-service';


const collectionRequest = "collectionRequest"
const handledKey = "getnull"


export default {
    getCurrentStatus,
    submitRequestAdvance2,
    cancelReqeustAdvance2
}



function getCurrentStatus(url, opts) {
    let caseId = getCaseIdFromUrl(url)
    let part = getPartFromUrl(url);
    let existings = db.getCollection(collectionRequest);
    let existing = existings.find(x => x.caseId === caseId && x.part === part);

    if (existing) {
        delete existing.caseNumber
        delete existing.redNumber
        delete existing.blackNumber
        delete existing.description
        delete existing.lawyer

        return existing;
    } else if (!existing || url.toLowerCase().includes(handledKey)) {

        let model = {
            id: null,
            caseId,
            part,
            refNumber: null,
            status: StatusPayment['ยังไม่ได้ทำรายการ'],
            items: []
        }

        let records = Math.floor(Math.random() * 6) + 1;

        for (let i = 0; i < records; i++) {
            let item = {}
            item.id = getUID();
            item.name = `advance ${i + 1}`;
            item.amount = null;
            model.items.push(item);
        }
        return model;
    }
}



function submitRequestAdvance2(url, opts) {
    let request = opts.body;
    let id = request.id;
    let payment = fakePaymentIncase.getCurrentStatus2();
    let index = payment.items.findIndex(x => x.id === PaymentPart.advance);
    let advance = fakePaymentIncase.initialAdvance();
    advance.id = PaymentPart.advance;
    advance.status = StatusPayment['รออนุมัติ'];
    advance.latestDate = getCurrentTime();
    advance.showPanel = true
    advance.canEdit = false
    advance.canCancel = true

    payment.items[index] = advance

    return payment;
}

function cancelReqeustAdvance2(url, opts) {
    let request = opts.body;
    let id = request.id;
    let payment = fakePaymentIncase.getCurrentStatus2();
    let index = payment.items.findIndex(x => x.id == PaymentPart.advance);
    let advance = fakePaymentIncase.initialAdvance();
    advance.id = PaymentPart.advance;
    advance.status = StatusPayment['ยังไม่ได้ทำรายการ'];
    advance.latestDate = getCurrentTime();
    advance.showPanel = true
    advance.canEdit = true
    advance.canCancel = false

    payment.items[index] = advance

    return payment;
}

//================================ internal function




function getCaseIdFromUrl(url) {
    let value = getValueFromUrl({ url, key: "case" })
    return value
}


function getPartFromUrl(url) {
    let value = getValueFromUrl({ url, key: "part" })
    return value
}


function getValueFromUrl({ url, key }) {
    let arr = url.split('/')
    let index = arr.findIndex(x => x === key)
    let result = arr[index + 1]
    return result
}

