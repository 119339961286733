
import { getNamePrefixs } from './../js/dropdown-service';
import { InputType } from './../js/constants';


export function getView() {
    return [
        [
            {
                name: "namePrefix",
                text: "คำนำหน้า",
                type: InputType.dropdown,
                data: {
                    promise: getNamePrefixs(),
                    url: ""
                },
                rule: {
                    required: true
                }
            },
            {
                name: "firstName",
                text: "ชื่อ",
                type: InputType.text,
                rule: {
                    required: true
                }
            },
            {
                name: "lastName",
                text: "สกุล",
                type: InputType.text,
                rule: {
                    required: true
                }
            },
            {
                name: "idCardNumber",
                text: "เลขที่บัตรประชาชน",
                type: InputType.text
            }
        ],
        [
            {
                name: "code",
                text: "Code",
                type: InputType.text
            },
            {
                name: "lawyerLicenseNumber",
                text: "เลขที่ใบอนุญาตให้เป็นทนายความ",
                type: InputType.text
            },
            {
                name: "email",
                text: "Email",
                type: InputType.text,
                rule: {
                    required: true,
                    //email: true
                }
            },

            {
                name: "telNo",
                text: "เบอร์โทร",
                type: InputType.text
            }
        ],
        [
            {
                type: "section",
                text: "Credential"
            }
        ],
        [
            {
                name: "userName",
                text: "User Name",
                type: InputType.text,
                disabled: true,
                rule: {
                    min: 7,
                    max: 10
                }
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
            {
                type: InputType.offset
            },
        ]
    ]
}
