<template>
  <div>
    <show-model :text="value"></show-model>

    <TableWithAddRecord
      class="mt-1"
      style="margin-top:-40px;"
      :rows="rows"
      v-model="items"
      :showAdd="!disabled"
      :showRemove="!disabled"
    >
      <template v-slot:content="obj">
        <v-row>
          <v-col>
            <render-input
              :disabled="disabled"
              :input="fieldConfig.actionDate"
              v-model="obj.item.actionDate"
            ></render-input>
          </v-col>
          <v-col>
            <render-input
              :disabled="disabled"
              :input="fieldConfig.songKooKwamCost"
              v-model="obj.item.songKooKwamCost"
            ></render-input>
          </v-col>
          <v-col>
            <render-input
              :disabled="disabled"
              :input="fieldConfig.delivereNamkamDigital"
              v-model="obj.item.delivereNamkamDigital"
            ></render-input>
          </v-col>

        </v-row>
      </template>
    </TableWithAddRecord>
  </div>
</template>

<script>
import { InputType, PipaksaResultType } from "./../../js/constants";
import { getDeptTypes } from "./../../js/dropdown-service";
import TableWithAddRecord from "./../../components/custom/table-with-add-record";
import { getFakeUrl, fakeAbbr } from "./../../_helpers/fake-url";
import { sumList } from "../../js/custom";

export default {
  props: {
    value: {
      type: Object,
    },
    model: Object,
    disabled: Boolean,
  },
  components: {
    TableWithAddRecord,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    summaryModel: {
      get() {
        let result = {};
        if (this.value && this.value.summary) {
          result = this.value.summary;
        }

        return result;
      },
      set(val) {
        this.value.summary = val;
      },
    },
    items: {
      get() {
        let result = [];
        if (this.value && this.value) {
          result = this.value;
        }
        return result;
      },
      set(val) {
        this.value = val;
      },
    },
    fieldConfig() {
      //todo: change label afterwards
      return {
        actionDate: {
          name: "actionDate",
          text: "วันที่",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
        songKooKwamCost: {
          name: "songKooKwamCost",
          text: "ค่าส่งคำคู่ความ (ค่านำคำบังคับ)",
          type: InputType.number,
          decimal: 2,
        },
        delivereNamkamDigital: {
          name: "delivereNamkamDigital",
          text: "ประกาศสื่อ",
          type: InputType.checkBox,
        },
      };
    },

    rows() {
      return [_.toArray(this.fieldConfig)];
    },
  },
};
</script>


<style >
.red--text .v-input input {
  color: #ff5722 !important;
}

.red--text
  .v-input.v-input--is-disabled
  .v-input__slot
  .v-text-field__slot
  input {
  color: #ff5722 !important;
}

.red--text .v-list-item__content .v-list-item__subtitle {
  color: #f44336 !important;
  caret-color: #f44336 !important;
}

.label-summary {
  padding-top: 15px;
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-right: 24px;
}
</style>
