import { getUID, urlToPayload, filterString, getDropdownItem } from '../js/custom';
import { StatusSue, StatusCase, DateFormat, UserRole } from '../js/constants';
import { toBeautyFormat } from '../js/date-helper';
import moment from 'moment'

import store from './../_store'

const colletionName = 'relative'
const collectionStatus = 'relative-status'



export default {
    item_cancelBeforeFong,
    item_alreadyNotice,
    item_alreadyDocumentFong,
    item_jangTonFong,
    item_sendNotificationToInputWanFong,
    item_confirmCloseCasewithOokmaiTang,
    item_jangNgodMaiTang,
    item_yomkwamJaingernKrob,
    getPipakaResults
}

function getPipakaResults() {
    let items = []
    // items.push(getMockup())
    // items.push(getMockup())
    // items.push(getMockup())

    let summary = {};
    summary.field1 = 11;
    summary.field2 = 2222;

    return {
        items,
        summary
    };


    function getMockup() {
        return {
            field1: 1111,
            field2: null,
            field3: 11111,
            field4: 11111

        }
    }
}


function item_cancelBeforeFong(url, opts) {
    let statusCase = getDropdownItem(StatusCase['ปิดเคส'])
    statusCase.reason = getDropdownItem(StatusCase['ปิดเคส'].reason['งดฟ้อง'])

    let obj = {
        statusCase,
        statusSue: null
    }

    return returnModel(obj)
}

function item_alreadyNotice(url, opts) {
    let obj = {
        statusCase: getDropdownItem(StatusCase["ทำ Notice เสร็จแล้ว"]),
        statusSue: null
    }

    return returnModel(obj)
}






function item_alreadyDocumentFong(url, opts) {
    let obj = {
        statusCase: getDropdownItem(StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"]),
        statusSue: null
    }

    return returnModel(obj)
}

function item_jangTonFong(url, opts) {
    let obj = {
        statusCase: getDropdownItem(StatusCase["แจ้งถอนฟ้อง"]),
        statusSue: getDropdownItem(StatusSue.yuenFong)
    }

    return returnModel(obj)
}

function item_sendNotificationToInputWanFong(url, opts) {
    let obj = {
        statusCase: getDropdownItem(StatusCase["รอวันฟ้องจากทนาย"]),
        statusSue: null
    }

    return returnModel(obj)
}

function item_confirmCloseCasewithOokmaiTang(url, opts) {
    let statusCase = getDropdownItem(StatusCase['ปิดเคส'])
    statusCase.reason = getDropdownItem(StatusCase['ปิดเคส'].reason['ออกหมายตั้ง'])

    let obj = {
        statusCase,
        statusSue: getDropdownItem(StatusSue.ookmaiTang)
    }

    return returnModel(obj)
}


function item_yomkwamJaingernKrob(url, opts) {
    let statusCase = getDropdownItem(StatusCase['ปิดเคส'])
    statusCase.reason = getDropdownItem(StatusCase['ปิดเคส'].reason['ปิดเคสทำยอม'])

    let obj = {
        statusCase,
        statusSue: getDropdownItem(StatusSue.ookmaiTang)
    }

    return returnModel(obj)
}


function item_jangNgodMaiTang(url, opts) {
    let body = opts.body;
    let obj = {
        statusCase: getDropdownItem(StatusCase["แจ้งงดหมายตั้ง"]),
        statusSue: body.statusSue
    }

    return returnModel(obj)
}


function returnModel(obj) {
    let model = getModel();
    Object.assign(model, obj)
    return model

    function getModel() {
        let model = _.cloneDeep(store.state.caseinfo.model)
        return model;
    }
}


