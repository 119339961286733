import { PaymentPart, StatusPayment } from './../js/constants';
import { getDropdownItem } from '../js/custom';
import mockLawyers from '../_mock-data/dropdown/lawyer';
import { getStatusSue } from './../js/dropdown-service';

var statusSues = [];

(async () => {
    let res = await getStatusSue();
    statusSues = res.items
})();

export default {
    getRawItems,
    submitRawItems,
}


function getRawItems(url, opts) {
    let items = []

    let itemNumber = Math.floor(Math.random() * 30) + 4
    for (let i = 0; i < itemNumber; i++) {
        items.push(getPaymentModel())
    }

    return {
        items
    }
}


function submitRawItems(url, opts) {
    let request = opts.body;
    // return model;
}


export function getPaymentModel() {
    let paymentParts = _.toArray(PaymentPart)

    return {
        caseId: "57ee054f-fe9f-4257-3b98-08d7aeedae28",
        part: _.sample(paymentParts),
        caseNumber: "",
        redNumber: "",
        blackNumber: "",
        court: {
            id: "",
            text: ""
        },
        description: "Lorem ipsum dolor sit amet",
        amount: Math.floor(Math.random() * 20000) + 1000,
        status: getDropdownItem(StatusPayment['ยังไม่ได้ทำรายการ']),
        statusSue: _.sample(statusSues),
        lawyer: _.sample(mockLawyers)
    }
}