import { getUID, urlToPayload, filterString } from './../js/custom';
import db from '../_helpers/fake-db';


function moveContract(url, opts) {
    return {
        newCaseId: 'test new Case id'
    }
}

function sendDataToLawyer(url) {

}


function get() {
    return {
        "creditor": {
            "id": "39b0a7f5-c033-44c6-8191-1fc570013048",
            "text": "AEON",
            "parentId": null
        },
        "lawyer": {
            "id": "282893bd-cf25-4e27-8e49-12bdfb5e6f54",
            "text": "นายปริญญา ชุมพล",
            "parentId": null
        },
        "court": {
            "id": "b334ae9e-0df5-4fe1-80e6-04842f2434d5",
            "text": "จังหวัดสมุทรสาคร",
            "parentId": null
        },
        "caseNumber": "AE12620005",
        "batchNo": "1262",
        "batchSeq": 5,
        "deptor": {
            "namePrefix": {
                "id": "977416de-6516-a083-5cfa-5d3d5cb97c41",
                "text": "นาย",
                "parentId": null
            },
            "namePrefixRemark": null,
            "name": {
                "firstName": "อาเขตต์",
                "lastName": "สอนพรม"
            },
            "address": {
                "houseNumber": "39/126",
                "moo": "8",
                "trok": null,
                "soi": null,
                "road": null,
                "province": {
                    "code": "74",
                    "thaiName": "สมุทรสาคร",
                    "englishName": "Samut Sakhon",
                    "id": "68e8f8f2-a573-4fc5-b1e1-13c5983967cc",
                    "changedDate": "2019-02-14T00:00:00",
                    "changedBy": "system"
                },
                "district": {
                    "code": "7401",
                    "thaiName": "เมืองสมุทรสาคร",
                    "englishName": "Mueang Samut Sakhon",
                    "provinceId": "68e8f8f2-a573-4fc5-b1e1-13c5983967cc",
                    "province": null,
                    "id": "248d8241-e897-4caf-9fea-205238373980",
                    "changedDate": "2019-02-14T00:00:00",
                    "changedBy": "system"
                },
                "subDistrict": {
                    "code": "740110",
                    "thaiName": "ท่าทราย",
                    "englishName": "Tha Sai",
                    "districtId": "248d8241-e897-4caf-9fea-205238373980",
                    "district": null,
                    "postalCode": "74000",
                    "latitude": 13.571,
                    "longtitude": 100.266,
                    "id": "4d8de279-c2bd-4614-9484-a9e9b440edf3",
                    "changedDate": "2019-02-14T00:00:00",
                    "changedBy": "system"
                },
                "telNo": null,
                "faxNo": null,
                "isActive": true
            },
            "race": null,
            "nationality": {
                "id": "44e508d5-42d5-48f6-a2de-6c0e75c164ce",
                "text": "ไทย",
                "parentId": null
            },
            "occupation": null,
            "age": "31",
            "id": "00000000-0000-0000-0000-000000000000",
            "changedDate": null,
            "changedBy": null
        },
        "idCardNo": "1100200374629",
        "noticeDate": null,
        "noticePrintDate": null,
        "plaintDate": "23/12/2562",
        "deptSummaryDate": "21/11/2562",
        "plaintDetail": null,
        "generatePlaintURL": null,
        "remark": null,
        "issueDate": "IssueDate",
        "issuePlace": "IssuePlace",
        "id": "83fde920-a09d-498c-23c2-08d77473ce97",
        "changedDate": null,
        "changedBy": null
    }
}

export default {
    get,
    moveContract,
    sendDataToLawyer

};

