<template>
  <div>
    <v-row>
      <v-col
        offset="1"
        cols="10"
      >
        <v-row>
          <v-col md="4">
            <render-input
              ref="mainLawyer"
              :input="fieldConfig.mainLawyer"
              v-model="currentMainLawyer"
              :autoFetchItems="false"
            ></render-input>
          </v-col>
        </v-row>

        <v-simple-table dense>
          <thead>
            <tr class="teal lighten-5">
              <th colspan="100%">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-inline-block"
                  >
                    <render-input
                      ref="addLawyer"
                      :input="fieldConfig.addLawyer"
                      v-model="newLawyers"
                      :autoFetchItems="false"
                    ></render-input>
                  </v-col>
                  <v-col>
                    <v-chip
                      class="ml-10 mb-3"
                      color="primary"
                      :disabled="!newLawyers"
                      text
                      @click="addLawyers()"
                    >
                      <v-icon left>add_circle</v-icon>Add Data
                    </v-chip>
                  </v-col>
                </v-row>
              </th>
            </tr>
            <tr>
              <th
                class="text-center"
                width="10%"
              >No.</th>
              <!-- <th class="text-center" width="40%">Id</th>
              <th class="text-center" width="30%">Name</th> -->
              <th
                class="text-center"
                width="70%"
              >Name</th>
              <th
                class="text-center"
                width="20%"
              >Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in itemsInTable"
              :key="index"
              :class="{ 'success white--text': item.isMainLawyer }"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <!-- <td>{{ item.id }}</td> -->
              <td>{{ item.text }}</td>
              <td class="text-center">
                <v-icon
                  v-if="canRemove"
                  color="error"
                  @click="removeLawyer(item, index)"
                >delete</v-icon>
              </td>
            </tr>
            <tr v-show="itemsInTable.length === 0">
              <td
                colspan="100%"
                class="text-center"
              >No Result. Please add</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getLawyers } from "./../js/dropdown-service";
import { InputType } from "../js/constants";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
    },
  },
  data() {
    return {
      newLawyers: null,
      checked: null,
      allOptions: [],
    };
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.$refs.mainLawyer.setItems(newVal);
      },
    },
    filteredOption: {
      handler(newVal) {
        this.$refs.addLawyer.setItems(newVal);
      },
    },
  },
  created() {
    getLawyers().then((res) => {
      this.allOptions = res.items;
    });
  },
  mounted() {
    this.defaultCheked();
  },
  methods: {
    addLawyer(selected) {
      let indexExisting = this.value.findIndex((x) => x.id === selected.id);
      if (indexExisting < 0) {
        let prepareToAdd = Object.assign({}, selected, { isMainLawyer: false });
        this.value.push(prepareToAdd);
      }
    },
    addLawyers() {
      this.newLawyers.forEach((newLaw) => {
        this.addLawyer(newLaw);
      });

      this.newLawyers = null;
    },
    removeLawyer(lawyer, index) {
      this.value.splice(index, 1);
    },
    defaultCheked() {
      const checkedsMain = this.value.filter((x) => x.isMainLawyer);
      if (checkedsMain.length > 1) {
        this.showAlertError("No have main lawyer.");
      } else {
        let checked = checkedsMain[0];
        if (checked) {
          this.checked = checked.id;
        }
      }
    },
  },
  computed: {
    itemsInTable: {
      get() {
        return this.value;
      },
    },
    filteredOption() {
      let existing = this.value.map((x) => {
        return x.id;
      });

      let filtered = this.allOptions.filter((x) => !existing.includes(x.id));
      filtered = _.orderBy(filtered, ["text"], ["asc"]);

      return filtered;
    },
    canRemove() {
      if (!this.model.id) {
        return true;
      }
      return this.model.permission.canRemove;
    },
    mainLawyerName() {
      if (this.checked) {
        let lawyer = this.value.find((x) => x.id === this.checked);
        return lawyer ? lawyer.text : "";
      }
      return "";
    },
    currentMainLawyer: {
      get() {
        let mainLawyer = this.value.find((x) => x.isMainLawyer);
        if (mainLawyer) {
          return mainLawyer;
        } else {
          return null;
        }
      },
      set(val) {
        this.value.forEach((item) => {
          if (val && val.id === item.id) {
            item.isMainLawyer = true;
          } else {
            item.isMainLawyer = false;
          }
        });
      },
    },
    fieldConfig() {
      return {
        mainLawyer: {
          name: "mainLawyer",
          text: "ทนายหลัก",
          type: InputType.dropdown,
        },
        addLawyer: {
          name: "addLawyer",
          text: "เพิ่มทนาย",
          type: InputType.dropdownMultiple,
        },
      };
    },
  },
};
</script>
