import {
    StatusSue,
    StatusCase,
    DateFormat,
    UserRole,
    InputType,
    NgodFong,
    Chalo,
} from "../../../js/constants";

import * as http from "../../../js/service";
import store from "./../../../_store";

export const item_sendNotificationToInputWanFong = {
    title: "ส่ง line 1st ให้ทนายเข้ามาใส่วันฟ้อง",
    template: '1',
    icon: "fab fa-line",
    type: "success",
    position: "top",
    buttonText: "แจ้งให้ทนายเข้ามาใส่วันฟ้อง",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        return false;// 14/01/66 กลับมาตรวจสอบอีกรอบ ปรากฏว่าาหลังบ้านไม่มี api ให้เรียกใช้แล้ว เลยกลับมาเอาออก

        let arr = [];
        arr.push(StatusCase["ทำ Notice เสร็จแล้ว"].id);

        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;

        if (statusCase && arr.includes(statusCase.id)) {
            return true;
        }

        return false;
    },
    func: () => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/sendNotificationToInputWanFong`;

        return http.post(url);
    },
};

export const item_alreadyNotice = {
    name: "ทำ Notice เสร็จแล้ว",
    title: "ทำ Notice เสร็จแล้ว",
    template: '1',
    icon: "phone_forwarded",
    type: "success",
    position: "top",
    buttonText: "ฉันทำเอกสาร Notice เสร็จแล้ว",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let arr = [];
        arr.push(StatusCase["พร้อมทำ Notice"].id);

        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;

        if (statusCase && arr.includes(statusCase.id)) {
            return true;
        }

        return false;
    },
    func: () => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/alreadyNotice`;

        return http.post(url);
    },
};

export const item_alreadyDocumentFong = {
    name: "ทำเอกสารฟ้องเสร็จแล้ว",
    title: "ตรวจเอกสารฟ้องแล้ว (พร้อมให้ทนายเข้ามา Download)",
    template: '1',
    icon: "event_available",
    type: "success",
    position: "top",
    buttonText: "ยืนยัน",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let arr = [];
        arr.push(StatusCase["เตรียมเอกสารฟ้อง"].id);

        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;
        if (statusCase && arr.includes(statusCase.id)) {
            return true;
        }
        return false;
    },
    func: () => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/alreadyDocumentFong`;

        return http.post(url);
    },
};

export const item_confirmCloseCasewithOokmaiTang = {
    name: "ปิดเคส -> ออกหมายตั้ง",
    title: "ปิดเคส (ออกหมายตั้ง)",
    template: '1',
    icon: "check_circle_outline",
    type: "success",
    position: "top",
    buttonText: "ยืนยันปิดเคสแบบออกหมายตั้ง",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;

        if (
            statusCase &&
            statusCase.id === StatusCase["ระหว่างยื่นฟ้อง"].id &&
            statusSue &&
            statusSue.id === StatusSue.ookmaiTang.id
        ) {
            return true;
        }

        return false;
    },
    func: () => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/confirmCloseCasewithOokmaiTang`;

        return http.post(url);
    },
};

// export const item_yomkwamJaingernKrob = {
//     name: "ปิดเคส -> ปิดเคสทำยอม",
//     title: "ปิดเคส (ปิดเคสทำยอม)",
//     template: '1',
//     icon: "check_circle_outline",
//     type: "success",
//     position: "top",
//     buttonText: "ลูกหนี้ยอมความ ปิดเคสทำยอมแล้ว",
//     permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
//     condition: () => {
//         let model = _.cloneDeep(store.state.caseinfo.model);
//         let { statusCase, statusSue } = model;

//         if (
//             statusCase &&
//             statusCase.id === StatusCase["ระหว่างยื่นฟ้อง"].id &&
//             statusSue &&
//             statusSue.id === StatusSue.praneePranom.id
//         ) {
//             return true;
//         }

//         return false;
//     },
//     func: () => {
//         let caseId = store.state.caseinfo.id;
//         let url = `/case/${caseId}/action/yomkwamJaingernKrob`;

//         return http.post(url);
//     },
// };

export const item_deptorPidNud = {
    name: "ปิดเคส -> ลูกหนี้ผิดนัด",
    title: "ลูกหนี้ผิดนัดชำระ",
    template: '2',
    icon: "check_circle_outline",
    type: "warning",
    position: "top",
    rows: [
        [
            {
                name: "amount",
                text: "ยอดชำระเข้ามาทั้งหมด",
                type: InputType.number,
                decimal: 2,
                rule: {
                    required: true,
                },
                columnClass: "col-sm-12 col-md-3",
            },
            {
                name: "date",
                text: "วันที่แจ้งผิดนัด",
                type: InputType.datePicker,
                rule: {
                    required: true,
                },
                columnClass: "col-sm-12 col-md-3",
            },
            {
                name: "lastPaymentDatePraneepranom",
                text: "วันที่ชำระล่าสุด",
                type: InputType.datePicker,
                rule: {
                },
                columnClass: "col-sm-12 col-md-3",
            },
        ],
    ],
    buttonText: "แจ้งออกหมาย (ผิดนัด)",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;

        if (
            statusCase &&
            statusCase.id === StatusCase["ระหว่างยื่นฟ้อง"].id &&
            statusSue &&
            statusSue.id === StatusSue.praneePranom.id
        ) {
            return true;
        }

        return false;
    },
    func: (payload) => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/pidnudChamRa`;

        return http.post(url, payload);
    },
};

// ================  bottom  ===================

export const item_jangTonFong = {
    name: "แจ้งถอนฟ้อง",
    title: "แจ้งถอนฟ้อง",
    template: '2',
    icon: "pan_tool",
    type: "warning",
    position: "bottom",
    rows: [
        [
            {
                name: "actionDate",
                text: "วันที่แจ้งถอนฟ้อง",
                type: InputType.datePicker,
                rule: {
                    required: true
                },
                columnClass: 'col-md-4'

            },
            {
                name: "reasonTonFong",
                text: "เหตุผล",
                type: InputType.dropdown,
                rule: {
                    required: true
                },
                data: {
                    url: '/dropdown/reasonToTonFong'
                },
                columnClass: 'col-md-8'
            },
            {
                name: "reasonTonFongOther",
                text: "เหตุผลอื่นๆ",
                type: InputType.textArea,
                columnClass: 'col-md-6'
            },
        ]
    ],
    buttonText: "แจ้งถอนฟ้อง",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;

        if (
            statusCase &&
            statusCase.id === StatusCase["ระหว่างยื่นฟ้อง"].id &&
            statusSue &&
            statusSue.id === StatusSue.yuenFong.id
        ) {
            return true;
        }

        return false;
    },
    func: (payload) => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/jangTonFong`;

        return http.post(url, payload);
    },
};

export const item_jangNgodMaiTang = {
    name: "แจ้งงดหมายตั้ง",
    title: "แจ้งงดหมายตั้ง",
    template: '2',
    icon: "pan_tool",
    type: "warning",
    position: "bottom",
    rows: [
        [
            {
                name: "actionDate",
                text: "วันที่แจ้งงดหมายตั้ง",
                type: InputType.datePicker,
                rule: {
                    required: true
                },
                columnClass: 'col-md-4'

            },
            {
                name: "reasonNgodMaiTang",
                text: "เหตุผล",
                type: InputType.dropdown,
                rule: {
                    required: true
                },
                data: {
                    url: '/dropdown/ReasonNgodMaiTang'
                },
                columnClass: 'col-md-8'
            },
            {
                name: "reasonNgodMaiTangOther",
                text: "เหตุผลอื่นๆ",
                type: InputType.textArea,
                columnClass: 'col-md-6'
            },
        ]
    ],
    buttonText: "แจ้งงดหมายตั้ง",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;

        let condition1 =
            statusCase && statusCase.id === StatusCase["ระหว่างยื่นฟ้อง"].id;
        let condition2 =
            statusSue &&
            [
                StatusSue.piPakSa.id,
                StatusSue.namKamBangKub.id,
                StatusSue.praneePranom.id,
            ].includes(statusSue.id);
        if (condition1 && condition2) {
            return true;
        }

        return false;
    },
    func: (payload) => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/jangNgodMaiTang`;

        return http.post(url, payload);
    },
};


// export const item_jangNgodMaiTang = {
//     name: "แจ้งงดหมายตั้ง",
//     title: "แจ้งงดหมายตั้ง",
//     template: '1',
//     icon: "pan_tool",
//     type: "warning",
//     position: "bottom",
//     buttonText: "แจ้งงดหมายตั้ง",
//     permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
//     condition: () => {
//         let model = _.cloneDeep(store.state.caseinfo.model);
//         let { statusCase, statusSue } = model;

//         let condition1 =
//             statusCase && statusCase.id === StatusCase["ระหว่างยื่นฟ้อง"].id;
//         let condition2 =
//             statusSue &&
//             [
//                 StatusSue.piPakSa.id,
//                 StatusSue.namKamBangKub.id,
//                 StatusSue.ookmaiTang.id,
//             ].includes(statusSue.id);
//         if (condition1 && condition2) {
//             return true;
//         }

//         return false;
//     },
//     func: () => {
//         let caseId = store.state.caseinfo.id;
//         let { statusSue } = store.state.caseinfo.model;
//         let url = `/case/${caseId}/action/jangNgodMaiTang`;

//         return http.post(url, { statusSue });
//     },
// };


export const item_returnCase = {
    name: "คืนงาน",
    title: "คืนงาน",
    template: '2',
    icon: "remove_circle_outline",
    type: "error",
    position: "bottom",
    rows: [
        [
            {
                name: "reasonReturnCase",
                text: "เหตุผล",
                type: InputType.dropdown,
                rule: {
                    required: true
                },
                data: {
                    url: '/dropdown/reasonReturnCase'
                },
                columnClass: 'col-md-8'
            },
            {
                name: "reasonReturnCaseOther",
                text: "เหตุผลอื่นๆ",
                type: InputType.textArea,
                columnClass: 'col-md-6'
            },
        ]
    ],
    buttonText: "คืนงาน",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let arr = [];
        arr.push(StatusCase["ปิดเคส"].id);
        arr.push(StatusCase["คืนงาน"].id);

        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;
        if (statusCase && !arr.includes(statusCase.id)) {
            return true;
        }
        return false;
    },
    func: (payload) => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/ReturnCase`;

        return http.post(url, payload);
    },
};

// export const item_returnCase = {
//     name: "คืนงาน",
//     title: "คืนงาน",
//     template: '1',
//     icon: "remove_circle_outline",
//     type: "error",
//     position: "bottom",
//     buttonText: "คืนงาน",
//     permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
//     condition: () => {
//         let arr = [];
//         arr.push(StatusCase["ปิดเคส"].id);
//         arr.push(StatusCase["คืนงาน"].id);

//         let model = _.cloneDeep(store.state.caseinfo.model);
//         let { statusCase, statusSue } = model;
//         if (statusCase && !arr.includes(statusCase.id)) {
//             return true;
//         }
//         return false;
//     },
//     func: () => {
//         let caseId = store.state.caseinfo.id;
//         let url = `/case/${caseId}/action/ReturnCase`;

//         return http.post(url);
//     },
// };


export const item_unlockCase = {
    name: "ปลดล็อค",
    titleFunc: () => {
        return `ปลดล็อค ${store.state.caseinfo.model.pauseReason}`
    },
    template: '1',
    icon: "remove_circle_outline",
    type: "error",
    position: "bottom",
    buttonText: "ปลดล็อค",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        if (store.state.caseinfo.model.pauseFlag) {
            return true;
        }
        return false
    },
    func: () => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/unlockCase`;

        return http.post(url);
    },
};

export const item_unlockSAM = {
    name: "ออกจาก SAM",
    title: "ออกจาก SAM",
    template: '1',
    icon: "remove_circle_outline",
    type: "error",
    position: "bottom",
    buttonText: "ออกจาก SAM",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        if (store.state.caseinfo.model.isInSAM) {
            return true;
        }
        return false
    },
    func: () => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/leaveSAM`;

        return http.post(url);
    },
};


export const item_ngodFong = {
    name: "งดฟ้อง",
    title: "งดฟ้อง",
    template: '3',
    icon: "remove_circle_outline",
    type: "error",
    position: "bottom",
    buttonText: "ยืนยัน",
    optionLabel: "เหตุผล",
    optionName: "reason",
    options: [
        {
            condition: () => {
                let arr = [];
                arr.push(StatusCase["New"].id);
                arr.push(StatusCase["รอสืบทายาท"].id);
                arr.push(StatusCase["พร้อมทำ Notice"].id);
                arr.push(StatusCase["ทำ Notice เสร็จแล้ว"].id);
                arr.push(StatusCase["รอวันฟ้องจากทนาย"].id);
                arr.push(StatusCase["เตรียมเอกสารฟ้อง"].id);
                arr.push(StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"].id);
                arr.push(StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id);

                let model = _.cloneDeep(store.state.caseinfo.model);
                let { statusCase, statusSue } = model;

                if (statusCase && arr.includes(statusCase.id)) {
                    return true;
                }

                return false;
            },
            value: NgodFong['งดฟ้อง'],
        },
        {
            condition: () => {
                let arr = [];
                arr.push(StatusCase["New"].id);
                arr.push(StatusCase["รอสืบทายาท"].id);
                arr.push(StatusCase["พร้อมทำ Notice"].id);
                arr.push(StatusCase["ทำ Notice เสร็จแล้ว"].id);
                arr.push(StatusCase["รอวันฟ้องจากทนาย"].id);
                arr.push(StatusCase["เตรียมเอกสารฟ้อง"].id);
                arr.push(StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"].id);
                arr.push(StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id);

                let model = _.cloneDeep(store.state.caseinfo.model);
                let { statusCase, statusSue } = model;

                if (statusCase && arr.includes(statusCase.id)) {
                    return true;
                }

                return false;
            },
            value: NgodFong['งดฟ้อง เพราะเข้า SAM'],
        }
    ],
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    func: (model) => {
        let caseId = store.state.caseinfo.id;
        let url = '';
        if (model.reason.id == NgodFong['งดฟ้อง'].id) {
            url = `/case/${caseId}/action/cancelBeforeFong`;
        } else if (model.reason.id == NgodFong['งดฟ้อง เพราะเข้า SAM'].id) {
            url = `/case/${caseId}/action/ngodFongEnterSAM`;
        }

        return http.post(url);
    },
};
item_ngodFong.condition = () => {
    return Object.values(item_ngodFong.options).some((option) => {
        return option.condition();
    });
}



// export const item_chalo = {
//     name: "ชะลอ",
//     title: "ชะลอ",
//     template: '3',
//     icon: "remove_circle_outline",
//     type: "error",
//     position: "bottom",
//     buttonText: "ยืนยัน",
//     optionLabel: "เหตุผล",
//     optionName: "reason",
//     options: [
//         {
//             condition: () => {
//                 let arr = [];
//                 arr.push(StatusCase["New"].id);
//                 arr.push(StatusCase["รอสืบทายาท"].id);
//                 arr.push(StatusCase["พร้อมทำ Notice"].id);
//                 arr.push(StatusCase["ทำ Notice เสร็จแล้ว"].id);
//                 arr.push(StatusCase["รอวันฟ้องจากทนาย"].id);
//                 arr.push(StatusCase["เตรียมเอกสารฟ้อง"].id);
//                 arr.push(StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"].id);
//                 arr.push(StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id);

//                 let model = _.cloneDeep(store.state.caseinfo.model);
//                 let { statusCase, statusSue } = model;

//                 if (statusCase && arr.includes(statusCase.id)) {
//                     return true;
//                 }


//                 return false;
//             },
//             value: Chalo['ชะลอการฟ้อง'],
//         },
//         {
//             condition: () => {
//                 let arr = [];
//                 arr.push(StatusCase["ระหว่างยื่นฟ้อง"].id);

//                 let model = _.cloneDeep(store.state.caseinfo.model);
//                 if (model.isInSAM) {
//                     return false;
//                 }

//                 let { statusCase, statusSue } = model;

//                 if (statusCase && arr.includes(statusCase.id)) {
//                     return true;
//                 }

//                 return false;
//             },
//             value: Chalo['ชะลอ เพราะเข้า SAM'],
//         }
//     ],
//     permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
//     func: (model) => {
//         let caseId = store.state.caseinfo.id;
//         let url = '';
//         if (model.reason.id == Chalo['ชะลอการฟ้อง'].id) {
//             url = `/case/${caseId}/action/chaloBeforeFong`;
//         } else if (model.reason.id == Chalo['ชะลอ เพราะเข้า SAM'].id) {
//             url = `/case/${caseId}/action/chaloEnterSAM`;

//         }

//         return http.post(url);
//     },
// };
// item_chalo.condition = () => {
//     return Object.values(item_chalo.options).some((option) => {
//         return option.condition();
//     });
// }


export const item_confirmCloseCaseJamnaiKadee = {
    name: "ปิดเคส -> จำหน่ายคดี",
    title: "ปิดเคส (จำหน่ายคดี)",
    template: '1',
    icon: "check_circle_outline",
    type: "error",
    position: "bottom",
    buttonText: "ยืนยันปิดเคสแบบจำหน่ายคดี",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let arr = [];
        arr.push(StatusCase["ปิดเคส"].id);
        arr.push(StatusCase["คืนงาน"].id);

        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;
        if (statusCase && !arr.includes(statusCase.id)) {
            return true;
        }
        return false;
    },
    func: () => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/confirmCloseCaseJamnaiKadee`;

        return http.post(url);
    },
};



export const item_chalo1 = {
    name: "ชะลอการฟ้อง",
    title: "ชะลอการฟ้อง",
    template: '2',
    icon: "remove_circle_outline",
    type: "error",
    position: "bottom",
    rows: [
        [
            {
                name: "reasonChaloFong",
                text: "เหตุผลชะลอฟ้อง",
                type: InputType.textArea,
                columnClass: 'col-md-6'
            },
        ]
    ],
    buttonText: "ชะลอการฟ้อง",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let arr = [];
        arr.push(StatusCase["New"].id);
        arr.push(StatusCase["รอสืบทายาท"].id);
        arr.push(StatusCase["พร้อมทำ Notice"].id);
        arr.push(StatusCase["ทำ Notice เสร็จแล้ว"].id);
        arr.push(StatusCase["รอวันฟ้องจากทนาย"].id);
        arr.push(StatusCase["เตรียมเอกสารฟ้อง"].id);
        arr.push(StatusCase["ทำเอกสารฟ้องเสร็จแล้ว"].id);
        arr.push(StatusCase["ส่งคำฟ้องให้ทนายแล้ว"].id);

        let model = _.cloneDeep(store.state.caseinfo.model);
        let { statusCase, statusSue } = model;

        if (statusCase && arr.includes(statusCase.id)) {
            return true;
        }


        return false;
    },
    func: (payload) => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/chaloBeforeFong`;

        return http.post(url, payload);
    },
};



export const item_chalo2 = {
    name: "เข้า SAM",
    title: "เข้า SAM",
    template: '2',
    icon: "remove_circle_outline",
    type: "error",
    position: "bottom",
    buttonText: "เข้า SAM",
    permission: [UserRole.ADMIN, UserRole.MANAGEMENT, UserRole.BACK_OFFICE],
    condition: () => {
        let arr = [];
        arr.push(StatusCase["ระหว่างยื่นฟ้อง"].id);

        let model = _.cloneDeep(store.state.caseinfo.model);
        if (model.isInSAM) {
            return false;
        }

        let { statusCase, statusSue } = model;

        if (statusCase && arr.includes(statusCase.id)) {
            return true;
        }

        return false;
    },
    func: (payload) => {
        let caseId = store.state.caseinfo.id;
        let url = `/case/${caseId}/action/EnterSAM`;

        return http.post(url, payload);
    },
};