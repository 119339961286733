import manageStorage from './../js/manage-localstorage'
import { validInternalCode } from "./../_specific/exportJs";
export default {
    namespaced: true,
    state: {
        title: null,
        displayName: null,
        userLocalStorage: false,
        forceInternal: 0,
    },
    getters: {
        isSupportMode: (state) => {
            let internalCode = null;
            let xx = state.forceInternal
            if (!_.isEmpty(manageStorage.getObject('internalCode'))) {
                internalCode = manageStorage.getObject('internalCode')
            }
            return validInternalCode(internalCode);
        }
    },
    mutations: {
        setTitle(state, newValue) {
            state.title = newValue;
        },
        setDisplayName(state, name) {
            state.displayName = name
        }
    }
}
