<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-simple-table dense>
          <tbody>
            <tr
              v-for="(item,index) in innerValue"
              :key="index"
            >
              <td class="text-center">
                <div class="mt-n6">{{index+1}}</div>
              </td>
              <td>
                <slot
                  name="content"
                  :item="item"
                >
                  <master-render
                    :rows="rows"
                    v-model="innerValue[index]"
                  ></master-render>
                </slot>
              </td>

              <td
                class="text-center"
                v-if="showRemove"
              >
                <v-icon
                  class="mt-n7"
                  color="error"
                  @click="remove(item,index)"
                >delete</v-icon>
              </td>
            </tr>

            <tr v-show="value.length === 0">
              <td
                colspan="100%"
                class="text-center"
              >No Result</td>
            </tr>
            <tr v-if="showAdd">
              <td
                colspan="100%"
                class="text-right"
              >
                <v-btn
                  color="primary mr-12"
                  class="white--text"
                  @click="addRecord()"
                >Add</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
      <v-col></v-col>
    </v-row>
  </div>
</template>

<script>
import { createDynamicModel } from "./../../js/custom";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    model: Object,
    rows: {
      type: Array,
      required: true,
    },
    showRemove: {
      type: Boolean,
      default: true,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    addRecord() {
      let record = {};
      createDynamicModel(this, record, this.rows);

      this.innerValue.push(record);
    },
    remove(item, index) {
      this.innerValue.splice(index, 1);
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

