
import { fakePrefix, fakeAbbr } from './../_helpers/fake-url';

import DocumentTypeService from './fake-document-type-service';
import DropdownService from './fake-dropdown-service';
import ProfileService from './fake-profile-service';
import LineService from './fake-line-service';
import ProvinceService from './fake-province-service';
import DistrictService from './fake-district-service';
import SubDistrictService from './fake-subdistrict-service';
import RelativeService from './fake-relative-service';
import CaseInfoService from './fake-caseinfo-service';
import UpdateSueStatusService from './fake-update-sue-status-service';
import UpdateCaseStatusService from './fake-update-case-status-service';
import DashboardService from './fake-dashboard-service';

import PaymentIncaseService from './fake-payment-in-case-service';
import PaymentAdvanceIncaseService from './fake-payment-advance-in-case-service';
import PaymentBulkWithdrawService from './fake-payment-bulk-withdraw-service';
import PaymentRequestListService from './fake-payment-request-list-service';
import PaymentBaiberkService from './fake-payment-baiberk-service';





function endpoint(options) {
    let opts = { ...options }//clone object
    let url = opts.url;

    let body = opts.body;
    let fakeIndex = url.indexOf(fakePrefix);
    if (fakeIndex < 0) {
        throw new Error('url not contains fake prefix')
    }

    let startRealUrl = url.indexOf('/')
    let realUrl = url.substring(startRealUrl)//

    let fakeContent = url.substring(0, startRealUrl).match(/\((.*)\)/);
    let fakeUrl = fakeContent[1].split('|')[1];//content in rounded brakets


    //dropdown
    if (url.includes('dropdown')) {
        return DropdownService.endpoint(url, opts)
    }

    //login
    if (fakeUrl === fakeAbbr.login) {
        return {
            "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImY5OTQyZThkLWYwNGQtNDFkNi04ZjM2LTQ0OWU2ZTJjY2Q3NiIsIm5iZiI6MTU3MzczOTUyOSwiZXhwIjoxNTc0MzQ0MzI5LCJpYXQiOjE1NzM3Mzk1Mjl9._p6vN73GLcrspRxthHbDK48cPd6YbYQGaVqjyvHOaS4",
            "userName": "admin",
            "displayName": body.userName,
            "role": "ADMIN",
            "expiration": 86400,
        }
    }
    //change password
    if (fakeUrl === fakeAbbr.changePassword) {
        return 'Your password has been changed successfully'
    }
    //reset password
    if (fakeUrl === fakeAbbr.resetPassword) {
        return 'l#0sdfRd%'
    }

    //profile
    switch (fakeUrl) {
        case fakeAbbr.profile.get: return ProfileService.get(realUrl, opts);
        case fakeAbbr.profile.update: return ProfileService.update(realUrl, opts);
        case fakeAbbr.line.checkVerified: return LineService.checkVerified(realUrl, opts);
        case fakeAbbr.line.generateToken: return LineService.generateToken(realUrl, opts);
    }

    //caseinfo
    switch (fakeUrl) {
        case fakeAbbr.caseInfo.get: return CaseInfoService.get(realUrl, opts);
        case fakeAbbr.caseInfo.moveContract: return CaseInfoService.moveContract(realUrl, opts);
        case fakeAbbr.caseInfo.sendDataToLawyer: return CaseInfoService.sendDataToLawyer(realUrl, opts);
        case fakeAbbr.actionStatus.cancelBeforeFong: return UpdateCaseStatusService.item_cancelBeforeFong(realUrl, opts)
        case fakeAbbr.actionStatus.alreadyNotice: return UpdateCaseStatusService.item_alreadyNotice(realUrl, opts)
        case fakeAbbr.actionStatus.alreadyDocumentFong: return UpdateCaseStatusService.item_alreadyDocumentFong(realUrl, opts)
        case fakeAbbr.actionStatus.jangTonFong: return UpdateCaseStatusService.item_jangTonFong(realUrl, opts)
        case fakeAbbr.actionStatus.sendNotificationToInputWanFong: return UpdateCaseStatusService.item_sendNotificationToInputWanFong(realUrl, opts)
        case fakeAbbr.actionStatus.confirmCloseCasewithOokmaiTang: return UpdateCaseStatusService.item_confirmCloseCasewithOokmaiTang(realUrl, opts)
        case fakeAbbr.actionStatus.jangNgodMaiTang: return UpdateCaseStatusService.item_jangNgodMaiTang(realUrl, opts)
        case fakeAbbr.actionStatus.yomkwamJaingernKrob: return UpdateCaseStatusService.item_yomkwamJaingernKrob(realUrl, opts)


        case fakeAbbr.actionStatus.getPipakaResults: return UpdateCaseStatusService.getPipakaResults(realUrl, opts)
    }
    //dashboard
    switch (fakeUrl) {
        case fakeAbbr.dashboard.management_open: return DashboardService.getOpenCase(realUrl, opts);
        case fakeAbbr.dashboard.management_latesMonth: return DashboardService.getCaseLatestMonth(realUrl, opts);
        case fakeAbbr.dashboard.management_twelveMonthCompareOpen: return DashboardService.getTwelveMonthCompareOpen(realUrl, opts);
        case fakeAbbr.dashboard.management_getProportionOpenCase: return DashboardService.getProportionOpenCase(realUrl, opts);
        case fakeAbbr.dashboard.management_getTrendCaseNumber: return DashboardService.getTrendCaseNumber(realUrl, opts);
        case fakeAbbr.dashboard.management_getTrendOpenCaseAccumulate: return DashboardService.getTrendOpenCaseAccumulate(realUrl, opts);
        case fakeAbbr.dashboard.management_getLawyerPerformance: return DashboardService.getLawyerPerformance(realUrl, opts);
        case fakeAbbr.dashboard.getLawyerPerformanceTopTen: return DashboardService.getLawyerPerformanceTopTen(realUrl, opts);
        case fakeAbbr.dashboard.lawyer_getCaseNumberLatesMonth: return DashboardService.lawyer_getCaseNumberLatesMonth(realUrl, opts);
        case fakeAbbr.dashboard.lawyer_getOpenCaseNumber: return DashboardService.lawyer_getOpenCaseNumber(realUrl, opts);
        case fakeAbbr.dashboard.lawyer_getSuccessRateBackwards: return DashboardService.lawyer_getSuccessRateBackwards(realUrl, opts);

    }

    //Payment 
    switch (fakeUrl) {
        case fakeAbbr.paymentInCase.getCurrentStatus2: return PaymentIncaseService.getCurrentStatus2(realUrl, opts);
        case fakeAbbr.paymentInCase.requestPayment: return PaymentIncaseService.requestPayment(realUrl, opts);
        case fakeAbbr.paymentInCase.requestPayment2: return PaymentIncaseService.requestPayment2(realUrl, opts);
        case fakeAbbr.paymentInCase.approve: return PaymentIncaseService.approve(realUrl, opts);
        case fakeAbbr.paymentInCase.approve2: return PaymentIncaseService.approve2(realUrl, opts);
        case fakeAbbr.paymentInCase.getChecklist: return PaymentIncaseService.getChecklist(realUrl, opts);
        case fakeAbbr.paymentInCase.toggleChecklist: return PaymentIncaseService.toggleChecklist(realUrl, opts);
        case fakeAbbr.paymentInCase.getFile: return PaymentIncaseService.getFile(realUrl, opts);
        case fakeAbbr.paymentInCase.uploadFile: return PaymentIncaseService.uploadFile(realUrl, opts);
        case fakeAbbr.paymentInCase.cancelApprove: return PaymentIncaseService.cancelApprove(realUrl, opts);


        case fakeAbbr.paymentAdvanceInCase.request2: return PaymentAdvanceIncaseService.submitRequestAdvance2(realUrl, opts);
        case fakeAbbr.paymentAdvanceInCase.cancel2: return PaymentAdvanceIncaseService.cancelReqeustAdvance2(realUrl, opts);

        case fakeAbbr.paymentBulkWithdraw.getRawItems: return PaymentBulkWithdrawService.getRawItems(realUrl, opts);
        case fakeAbbr.paymentBulkWithdraw.bulkWithdraw: return PaymentBulkWithdrawService.submitRawItems(realUrl, opts);

        case fakeAbbr.paymentRequestList.getRequestList: return PaymentRequestListService.search(realUrl, opts);
        case fakeAbbr.paymentRequestList.approve: return PaymentRequestListService.approve(realUrl, opts);
        case fakeAbbr.paymentRequestList.createBaiBerk: return PaymentRequestListService.createBaiBerk(realUrl, opts);
        case fakeAbbr.paymentRequestList.cancelApprove: return PaymentRequestListService.cancelApprove(realUrl, opts);

        case fakeAbbr.baiberk.search: return PaymentBaiberkService.search(realUrl, opts);
        case fakeAbbr.baiberk.paid: return PaymentBaiberkService.paid(realUrl, opts);
        case fakeAbbr.baiberk.get: return PaymentBaiberkService.get(realUrl, opts);
        case fakeAbbr.baiberk.cancelPaid: return PaymentBaiberkService.cancelPaid(realUrl, opts);
        case fakeAbbr.baiberk.deleteBaiberk: return PaymentBaiberkService.deleteBaiberk(realUrl, opts);
    }

    //Document Type master
    switch (fakeUrl) {
        case fakeAbbr.documentType.search: return DocumentTypeService.search(realUrl, opts);
        case fakeAbbr.documentType.get: return DocumentTypeService.get(realUrl, opts);
        case fakeAbbr.documentType.create: return DocumentTypeService.create(realUrl, opts);
        case fakeAbbr.documentType.update: return DocumentTypeService.update(realUrl, opts);
        case fakeAbbr.documentType.remove: return DocumentTypeService.remove(realUrl, opts);
    }
    //province
    switch (fakeUrl) {
        case fakeAbbr.province.search: return ProvinceService.search(realUrl, opts);
        case fakeAbbr.province.get: return ProvinceService.get(realUrl, opts);
        case fakeAbbr.province.create: return ProvinceService.create(realUrl, opts);
        case fakeAbbr.province.update: return ProvinceService.update(realUrl, opts);
        case fakeAbbr.province.remove: return ProvinceService.remove(realUrl, opts);
    }

    //district
    switch (fakeUrl) {
        case fakeAbbr.district.search: return DistrictService.search(realUrl, opts);
        case fakeAbbr.district.get: return DistrictService.get(realUrl, opts);
        case fakeAbbr.district.create: return DistrictService.create(realUrl, opts);
        case fakeAbbr.district.update: return DistrictService.update(realUrl, opts);
        case fakeAbbr.district.remove: return DistrictService.remove(realUrl, opts);
    }

    //sub-district
    switch (fakeUrl) {
        case fakeAbbr.subDistrict.search: return SubDistrictService.search(realUrl, opts);
        case fakeAbbr.subDistrict.get: return SubDistrictService.get(realUrl, opts);
        case fakeAbbr.subDistrict.create: return SubDistrictService.create(realUrl, opts);
        case fakeAbbr.subDistrict.update: return SubDistrictService.update(realUrl, opts);
        case fakeAbbr.subDistrict.remove: return SubDistrictService.remove(realUrl, opts);
    }


    //case relative
    switch (fakeUrl) {
        case fakeAbbr.relative.search: return RelativeService.search(realUrl, opts);
        case fakeAbbr.relative.get: return RelativeService.get(realUrl, opts);
        case fakeAbbr.relative.create: return RelativeService.create(realUrl, opts);
        case fakeAbbr.relative.update: return RelativeService.update(realUrl, opts);
        case fakeAbbr.relative.remove: return RelativeService.remove(realUrl, opts);
        case fakeAbbr.relative.getCurrentStatus: return RelativeService.getCurrentStatus(realUrl, opts);
        case fakeAbbr.relative.updateStatus: return RelativeService.updateStatus(realUrl, opts);
    }

    //update status case    
    switch (fakeUrl) {
        case fakeAbbr.updateStatus.postStatus: return UpdateSueStatusService.postStatus(realUrl, opts);
        case fakeAbbr.updateStatus.editStatus: return UpdateSueStatusService.editStatus(realUrl, opts);
        case fakeAbbr.updateStatus.getAll: return UpdateSueStatusService.getAll(realUrl, opts);
        case fakeAbbr.updateStatus.getOption: return UpdateSueStatusService.getOption(realUrl, opts);
        case fakeAbbr.updateStatus.deleteAction: return UpdateSueStatusService.deleteAction(realUrl, opts);
    }

}

export default { endpoint };


