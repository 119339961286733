export default [
    {
        id: "001",
        text: "เอกสาร ทร.",
        group: 1,
        groupName: "เอกสารเพื่อส่งฟ้อง"
    },
    {
        id: "002",
        text: "ออกสารต้นฉบับ",
        group: 1,
        groupName: "เอกสารเพื่อส่งฟ้อง"
    },
    {
        id: "03",
        text: "เอกสารฟ้อง",
        group: 2,
        groupName: "ออกสารต้นฉบับ"
    },
    {
        id: "04",
        text: "เอกสารจากศาล",
        group: 2,
        groupName: "ออกสารต้นฉบับ"
    },
    {
        id: "05",
        text: "ค่าใช้จ่าย",
        group: 2,
        groupName: "ออกสารต้นฉบับ"
    },
    {
        id: "06",
        text: "อื่นๆ",
        group: 2,
        groupName: "ออกสารต้นฉบับ"
    },

]