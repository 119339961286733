<template>
  <div>
    <custom-dialog
      @submit="submit"
      ref="customDialog"
      :canSubmit="canSubmit"
      :title="title"
      :size="size"
      :width="width"
      :model="model"
    >
      <show-model :text="model"></show-model>
      <v-lazy>
        <master-render
          :disabled="!canSubmit"
          :rows="rows"
          v-model="model"
          from="form"
          :mode="mode"
        ></master-render>
      </v-lazy>
    </custom-dialog>
  </div>
</template>


<script>
import CustomDialog from "./../../components/custom/custom-dialog";

import * as custom from "./../../js/custom";

export default {
  props: {
    config: Object,
    shortName: String,
    value: Object,
    form: Object,
    url: Object,
    permission: Object,
  },
  components: {
    "custom-dialog": CustomDialog,
  },
  data() {
    return {
      model: {},
      id: null,
      rows: [],
    };
  },
  created() {},
  methods: {
    showLoading() {
      this.$refs.customDialog.showLoading();
    },
    hideLoading() {
      this.$refs.customDialog.hideLoading();
    },
    showDialog(id) {
      this.id = id;
      this.rows = this.form.rows;

      this.initModel();
      this.model.id = id;
      this.showLoading();

      if (this.value) {
        this.model = this.value;
        this.hideLoading();
        this.emitGetDataSuccess();
      } else {
        if (id) {
          const url = this.urlGetData;

          this.$http.get(url + "/" + id).then((res) => {
            this.model = res;
            this.hideLoading();
            this.emitGetDataSuccess();
          });
        } else {
          this.hideLoading();
          this.emitGetDataSuccess();
        }
      }
      this.$refs.customDialog.showDialog();
    },
    emitGetDataSuccess() {
      this.$emit("get-data-success", this.model);
    },
    hideDialog() {
      this.$refs.customDialog.hideDialog();
    },
    initModel() {
      this.model = {};
      custom.createDynamicModel(this, this.model, this.rows);
      if (this.form.appendModel) {
        Object.assign(this.model, this.form.appendModel);
      }
    },
    submit() {
      let request;
      if (this.model.id) {
        let url = this.form.url || this.urlEditData;
        url = `${url}/${this.model.id}`;

        request = this.$http.put(url, this.model);
      } else {
        let url = this.form.url || this.urlAddData;
        request = this.$http.post(url, this.model);
      }

      request.then((res) => {
        this.$emit("result-submit", res);
      });
    },
  },

  computed: {
    mode() {
      return this.model.id ? "edit" : "add";
    },
    canSubmit() {
      let { permission } = this.model;
      if (!permission) {
        return null;
      }

      let valid = false;
      if (this.model.id) {
        valid = permission.canAdd;
      } else {
        valid = permission.canEdit;
      }

      if (this.form && this.form.conditionCanSubmit) {
        valid = valid && this.form.conditionCanSubmit(this.model);
      }

      return valid;
    },
    title() {
      if (this.id) {
        return `Edit ${this.shortName}`;
      } else {
        return `Add ${this.shortName}`;
      }
    },
    urlGetData() {
      return this.url.get;
    },
    urlAddData() {
      return this.url.add;
    },
    urlEditData() {
      return this.url.edit;
    },
    urlRemoveData() {
      return this.url.remove;
    },
    size() {
      return this.form.size;
    },
    width() {
      return this.form.width;
    },
  },
};
</script>
