import { get } from './service';
import * as constants from './constants';
import { getDropdownItem, toQueryString } from './custom';

import { getFakeUrl, fakeAbbr } from './../_helpers/fake-url';




export function getRoles() {
  let dropdownItems = [];
  dropdownItems.push({
    id: constants.UserRole.ADMIN,
    text: 'Admin'
  });
  dropdownItems.push({
    id: constants.UserRole.LAWYER,
    text: 'Lawyer'
  });
  dropdownItems.push({
    id: constants.UserRole.BACK_OFFICE,
    text: 'Back office'
  });
  dropdownItems.push({
    id: constants.UserRole.MANAGEMENT,
    text: 'Management'
  });
  return Promise.resolve({
    items: dropdownItems
  });
}

export function getRelativeStatuses() {
  let url = '/dropdown/case/caseDeptorHeirFindoutStatuses';
  return get(url);
}

export function getDeptTypes() {
  let url = '/dropdown/AEONLoanSubContractTypes';
  return get(url);
}

export function getNationalities() {
  let url = '/dropdown/nationalities'
  return get(url);
}

export function getLawyers() {
  let url = '/dropdown/lawyers';
  return get(url);
}

export function getNamePrefixs() {
  let url = '/dropdown/namePrefixs';
  return get(url);
}

export function getDocumentTypes() {
  let url = '/dropdown/documentTypes';
  return get(url);
}

export function getOnlyInquiryDocumentTypes() {
  let url = '/dropdown/InquiryDocumentTypes';
  return get(url);
}

export function getCourts() {
  let url = '/dropdown/courts';
  return get(url);
}

export function getCustomers() {
  // let url = '/dropdown/customers';
  // return get(url);


  let arr = [
    {
      "id": "39b0a7f5-c033-44c6-8191-1fc570013048",
      "text": "AEON",
      "parentId": null
    },
    {
      "id": "b696fc67-a616-443c-bb1c-1b8560e84ec1",
      "text": "RABBIT",
      "parentId": null
    },
    {
      "id": "983fa2e3-9d4c-4a42-bc18-b4de0194805b",
      "text": "AIRA",
      "parentId": null
    }]

  return Promise.resolve({
    items: arr
  });
}


export function getBranches({ customerId, authorize, includeCustomerText } = {}) {
  let url = '/dropdown/branches';
  url += "?" + toQueryString({ customerId, authorize, includeCustomerText });

  return get(url);
}

export function getInstallmentPayments() {
  let url = '/dropdown/InstallmentPayments';
  return get(url);
}

export function getLots(customerId) {
  let url = `/dropdown/lots?customerId=${customerId}`

  return get(url);
}


export function getDeptorRelatives() {
  let url = '/dropdown/case/deptorRelationshipTypes';
  return get(url);
}


export function getStatusSue() {
  // let obj = _.cloneDeep(constants.StatusSue);
  // let arr = _.toArray(obj)
  // let items = arr.map(x => getDropdownItem(x)).filter(x => !_.isEmpty(x))
  // return Promise.resolve({
  //   items
  // });

  let url = "/dropdown/sueStatuses"
  return get(url);
}

export function getStatusCase() {
  // let obj = _.cloneDeep(constants.StatusCase);
  // let arr = _.toArray(obj)
  // let items = arr.map(x => getDropdownItem(x))
  // return Promise.resolve({
  //   items
  // });

  let url = "/dropdown/caseStatuses"
  return get(url);
}

export function getStatusPayment() {
  let obj = _.cloneDeep(constants.StatusPayment);
  let arr = _.toArray(obj)
  let items = arr.map(x => getDropdownItem(x))
  return Promise.resolve({
    items
  });
}

export function getPaymentType() {
  let obj = _.cloneDeep(constants.PaymentType);
  let arr = _.toArray(obj)
  let items = arr.map(x => getDropdownItem(x))
  return Promise.resolve({
    items
  });
}

export function getReasonSueToLuen() {
  let url = "/dropdown/sueReasonToLuen"
  // url = getFakeUrl(fakeAbbr.dropdown.sue_reasonToLuean) + url;
  return get(url);
}

export function getProvinces() {
  let url = '/dropdown/provinces';

  return get(url);
}

export function getDistricts(provinceId) {
  let url = `/dropdown/districts?provinceId=${provinceId}`

  return get(url);
}

export function getSubDistricts(districtId) {
  let url = `/dropdown/subdistricts?districtId=${districtId}`

  return get(url);
}