import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, required } from 'vee-validate/dist/vee-validate.full';
import { validateThaiFormat } from './../js/date-helper';
import { DATE_PICKER_TYPE } from './../js/constants'
import { toCurrencyFormat } from './../js/custom';




// Use the provider immediately
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);


// extend validate rule
extend('dateformat', {
    validate(value, { type }) {
        let result = validateThaiFormat({ type: type, value });

        if (result.valid) {
            return true;
        }

        let format = ""
        if (type === DATE_PICKER_TYPE.DATE) {
            format = "dd/mm/yyyy";
        } else {
            format = "mm/yyyy";

        }
        return `Invalid format. It must be in ${format}`
    },
    params: ['type']
});

extend('timeformat', {
    validate(value) {
        var regex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

        let result = regex.test(value)
        if (result) {
            return true
        }

        return `Invalid format. It must be in hh:mm`
    },
});




extend('min_value', {
    validate(value, args) {
        value = value.toString().replace(/,/g, "");

        let num = parseFloat(value);
        let { min } = args;

        let errorText = `The {_field_} field must be ${toCurrencyFormat({ value: min, decimal: 0 })} or more`
        if (isNaN(num)) {
            return errorText;
        }

        if (num < min) {
            return errorText;
        }

        return true
    },
});


extend('max_value', {
    validate(value, args) {
        value = value.toString().replace(/,/g, "");

        let num = parseFloat(value);
        let { max } = args;

        let errorText = `The {_field_} field must be ${toCurrencyFormat({ value: max, decimal: 0 })} or less`
        if (isNaN(num)) {
            return errorText;
        }

        if (num > max) {
            return errorText;
        }

        return true
    },
});


extend('required', {
    ...required,
    // message: `{_field_} is required`,
    message: 'This field is required',
});
