<template>
  <div class="pagination">
    <v-row>
      <v-col class="nowrap">
        <span>แสดง</span>
        <v-select
          style="width: 90px"
          :items="limitList"
          v-model="currentLimit"
          outlined
          return-object
          dense
        ></v-select>
        <span>รายการ</span>
      </v-col>
      <v-col>
        <v-pagination
          v-show="items.length>0"
          v-model="currentPage"
          :length="totalPage"
          total-visible="10"
        ></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    limitPerpage: Number,
  },
  data() {
    return {
      currentPage: 1,
      currentLimit: 50,
      limitList: [20, 50, 100, 200, 500],
    };
  },
  watch: {
    currentPage: {
      handler(newVal, oldVal) {
        this.paginate();
      },
    },
    currentLimit: {
      handler(newVal, oldVal) {
        this.paginate();
      },
    },
    items: {
      immediate: true,
      handler(newVal, oldVal) {
        if (_.isArray(newVal) && _.isArray(oldVal)) {
          if (oldVal.length !== newVal.length) {
            this.currentPage = 1;
          }
        } else {
          this.currentPage = 1;
        }
        this.paginate();
      },
    },
  },
  mounted() {
    if (this.limitPerpage) {
      this.currentLimit = this.limitPerpage;
    }
  },
  methods: {
    paginate() {
      let limit = this.currentLimit;
      const start = limit * (this.currentPage - 1);

      let paginateItems = this.items.slice(start, start + limit);
      //define number to show in table
      for (var i = 0; i < paginateItems.length; i++) {
        paginateItems[i].no = start + (i + 1);
      }

      this.$emit("results", paginateItems);
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.items.length / this.currentLimit);
    },
  },
};
</script>