import { getCurrentTime, getDropdownItem, getUID, urlToPayload } from './../js/custom';
import { StatusPayment, PaymentType } from './../js/constants';

import PaymentRequestListService from './fake-payment-request-list-service';

const prefixRef = "BB"


var baiberkStatuses = [];


(() => {
    let includeStatus = [StatusPayment['ทำเอกสารเบิกแล้ว'].id, StatusPayment['จ่ายเงินแล้ว'].id]
    baiberkStatuses = _.toArray(StatusPayment).filter(x => includeStatus.includes(x.id))
})();

export default {
    search,
    get,
    paid,
    cancelPaid,
    deleteBaiberk
}






function search(url, opts) {
    let payload = urlToPayload(url)
    let type = _.toArray(PaymentType).find(x => x.id === payload['type.id'])

    let number = Math.floor(Math.random() * 30) + 1;

    let items = []
    for (let i = 0; i < number; i++) {
        items.push(getModel())
    }

    return {
        items
    }

    function getModel() {
        let obj = {
            id: getUID(),
            refNumber: getRunningNumber(),
            total: (Math.floor(Math.random() * 20000) + 2000),
            count: (Math.floor(Math.random() * 20) + 1),
            latestDate: getCurrentTime(),
            status: _.sample(baiberkStatuses)
        }

        logicFlag(obj)

        return obj;
    }

}

function cancelPaid(url, opts) {
    let baiberk = get();
    baiberk.status = getDropdownItem(StatusPayment['ทำเอกสารเบิกแล้ว']);

    logicFlag(baiberk);

    return baiberk;
}

function deleteBaiberk(url, opts) {

}



function get(url, opts) {
    let canPaid = _.sample([true, false])

    let baiberk = search(`${url}?`).items[0];
    if (canPaid) {
        baiberk.status = getDropdownItem(StatusPayment['ทำเอกสารเบิกแล้ว'])
    } else {
        baiberk.status = getDropdownItem(StatusPayment['จ่ายเงินแล้ว'])
    }
    baiberk.canPaid = canPaid;
    setRequests(baiberk)

    baiberk.total = Math.floor(Math.random() * 20000) + 2000;
    baiberk.count = baiberk.requests.length;


    baiberk.showCancelPaid = baiberk.status.id === StatusPayment['จ่ายเงินแล้ว'].id ? true : false;
    baiberk.showCancelBaiberk = baiberk.status.id === StatusPayment['ทำเอกสารเบิกแล้ว'].id ? true : false;


    function setRequests(baiberk) {
        let requests = PaymentRequestListService.search().items;
        requests.forEach(request => {
            request.id = getUID();
            request.status = baiberk.status;
        })
        baiberk.requests = requests;
    }

    return baiberk;
}



function paid(url, opts) {
    let baiberk = get('test url');
    baiberk.status = getDropdownItem(StatusPayment['จ่ายเงินแล้ว'])
    logicFlag(baiberk);

    return baiberk;
}

// === internal function
function logicFlag(baiberk) {
    baiberk.canPaid = baiberk.status.id === StatusPayment['ทำเอกสารเบิกแล้ว'].id ? true : false
    baiberk.showCancelPaid = baiberk.status.id === StatusPayment['จ่ายเงินแล้ว'].id ? true : false;
    baiberk.showCancelBaiberk = baiberk.status.id === StatusPayment['ทำเอกสารเบิกแล้ว'].id ? true : false;
}

function getRunningNumber() {
    return prefixRef + (Math.floor(Math.random() * 100) + 1).toString().padStart(6, '0');
}