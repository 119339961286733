export default [
    {
        "id": "77314a04-dcea-4dae-967c-d4a47729f497",
        "text": "นพ."
    },
    {
        "id": "b0b31d86-e2d8-4413-bd81-1b338397eb04",
        "text": "นาง"
    },
    {
        "id": "11e9c422-2b39-40f8-8d6c-2c1145122448",
        "text": "นางสาว"
    },
    {
        "id": "83b48220-7e13-493c-89be-adac9b722b53",
        "text": "นาย"
    },
    {
        "id": "a40e0e50-f755-467f-ae2e-20b3317d0964",
        "text": "พญ."
    },

]