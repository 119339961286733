export const fakePrefix = "fake-prefix"

export const fakeAbbr = {
    login: "login",
    changePassword: "change-password",
    resetPassword: "reset-password",
    profile: {
        get: "get-profile",
        update: "submit-profile"
    },
    documentType: {
        search: "search-documentType",
        get: "get-documentType",
        create: "add-documentType",
        update: "edit-documentType",
        remove: "remove-documentType",
    },
    province: {
        search: "search-province",
        get: "get-province",
        create: "add-province",
        update: "edit-province",
        remove: "remove-province",
    },
    district: {
        search: "search-district",
        get: "get-district",
        create: "add-district",
        update: "edit-district",
        remove: "remove-district",
    },
    subDistrict: {
        search: "search-subDistrict",
        get: "get-subDistrict",
        create: "add-subDistrict",
        update: "edit-subDistrict",
        remove: "remove-subDistrict",
    },
    relative: {
        search: "search-relative",
        get: "get-relative",
        create: "add-relative",
        update: "edit-relative",
        remove: "remove-relative",
        getCurrentStatus: "get-current-relative-status",
        updateStatus: "update-relative-status",
    },
    actionStatus: {
        cancelBeforeFong: "cancelBeforeFong",
        alreadyNotice: "alreadyNotice",
        alreadyDocumentFong: "alreadyDocumentFong",
        jangTonFong: "jangTonFong",
        sendNotificationToInputWanFong: "sendNotificationToInputWanFong",
        confirmCloseCasewithOokmaiTang: "confirmCloseCasewithOokmaiTang",
        jangNgodMaiTang: "jangNgodMaiTang",
        yomkwamJaingernKrob: "yomkwamJaingernKrob",
        getPipakaResults: 'getPipakaResults'
    },

    paymentInCase: {
        getCurrentStatus: "payment-in-case:getCurrentStatus",
        getCurrentStatus2: "payment-in-case:getCurrentStatus2",
        requestPayment: "payment-incase:requestPayment",
        requestPayment2: "payment-incase:requestPayment2",
        approve: "payment-in-case:approve",
        approve2: "payment-in-case:approve2",
        getChecklist: "payment-in-case:getChecklist",
        toggleChecklist: 'payment-in-case:toggle-checklist',
        getFile: 'payment-in-case:get-file',
        uploadFile: 'payment-in-case:upload-file',
        cancelApprove: 'payment-in-case:cancelApprove'
    },
    paymentAdvanceInCase: {
        getCurrentStatus: "payment-advance-incase:getCurrentStatus",
        request: "payment-advance-incase:request",
        request2: "payment-advance-incase:request2",
        cancel: "payment-advance-incase:cancel",
        cancel2: "payment-advance-incase:cancel2",
    },
    paymentBulkWithdraw: {
        getRawItems: "payment-bulk:get-raw-items",
        bulkWithdraw: "payment-bulk:bulk-withdraw",
    },
    paymentRequestList: {
        getRequestList: "payment-request-list:search",
        approve: "payment-request-list:approve",
        createBaiBerk: "payment-request-list:create-baiberk",
        cancelApprove: "payment-request-list:cancel-approve",
    },
    baiberk: {
        search: "baiberk:search",
        paid: "baiberk:paid",
        get: "baiberk:get",
        deleteBaiberk: "baiberk:delete",
        cancelPaid: "baiberk:cancel-paid"
    },
    caseInfo: {
        search: "search-case",
        sendDataToLawyer: 'send-data-to-lawyer',
        get: "case-get",
        getDeptorNames: "get-deptorNames",
        submitDeptorNames: "submit-deptorNames",
        getDeptorAddrs: "get-deptorAddrs",
        submitDeptorAddrs: "submit-deptorAddrs",
        moveContract: 'move-contract'
    },

    updateStatus: {
        postStatus: "post-status",
        editStatus: "edit-status",
        getAll: "get-all",
        getOption: "get-options",
        deleteAction: "delete-action"
    },
    line: {
        checkVerified: "check-verified",
        generateToken: "generateToken"
    },
    dropdown: {
        court_area: "court_area",
        master_court: "master_court",
        master_layer: "master_layer",
        master_customer: "master_customer",
        master_documentType: "master_documentType",
        master_namePrefix: "master_namePrefix",
        master_status: "master_status",
        sue_reasonToLuean: "sue_reasonToLuean"
    },
    dashboard: {
        management_open: "management_open",
        management_latesMonth: "management_latesMonth",
        management_twelveMonthCompareOpen: "management_twelveMonthCompareOpen",
        management_getProportionOpenCase: "management_getProportionOpenCase",
        management_getTrendCaseNumber: "management_getTrendCaseNumber",
        management_getTrendOpenCaseAccumulate: "management_getTrendOpenCaseAccumulate",
        management_getLawyerPerformance: "management_getLawyerPerformance",
        getLawyerPerformanceTopTen: "getLawyerPerformanceTopTen",
        lawyer_getCaseNumberLatesMonth: "lawyer_getCaseNumberLatesMonth",
        lawyer_getOpenCaseNumber: "lawyer_getOpenCaseNumber",
        lawyer_getSuccessRateBackwards: "lawyer_getSuccessRateBackwards"
    },


}

export function getFakeUrl(suffix) {
    return `(${fakePrefix}|${suffix})`
}

