<template>
  <div>
    <!-- table add lawyer -->
    <template v-if="type === inputType.tableLawyerCourt">
      <TableLawyerCourt
        v-model="value"
        :model="model"
      ></TableLawyerCourt>
    </template>
    <!-- end table add lawyer -->

    <template v-else-if="type === inputType.courtCovarageArea">
      <CourtCovarageArea
        v-model="value"
        :model="model"
      ></CourtCovarageArea>
    </template>

    <template v-else-if="type === inputType.courtCustomer">
      <CourtCustomer
        v-model="value"
        :model="model"
      ></CourtCustomer>
    </template>

    <!-- contract block -->
    <template v-else-if="type === inputType.refinanceList">
      <RefinanceList
        v-model="value"
        :model="model"
      ></RefinanceList>
    </template>

    <template v-else-if="type === inputType.pipaksaList">
      <UpdateStatusSueSaparateContract
        v-model="value"
        :model="model"
        :disabled="disabled"
      ></UpdateStatusSueSaparateContract>
    </template>
    <!-- end contract block -->

    <template v-else-if="type === inputType.namkamList">
      <UpdateStatusSueSaparateNamKamBangKab
        v-model="value"
        :model="model"
        :disabled="disabled"
      ></UpdateStatusSueSaparateNamKamBangKab>
    </template>
  </div>
</template>

<script>
import { InputType } from "./../js/constants";

import TableLawyerCourt from "./../views/table-lawyer-court";
import CourtCovarageArea from "./../views/court-covarage-area";
import CourtCustomer from "./../views/court-customer";
import RefinanceList from "./../views/case/refinance-list";
import UpdateStatusSueSaparateContract from "./../views/case/update-status-sue-saparate-contract";
import UpdateStatusSueSaparateNamKamBangKab from "./../views/case/update-status-sue-saparate-namkambangkab";

export const extendInputList = [
  InputType.tableLawyerCourt,
  InputType.courtCovarageArea,
  InputType.courtCustomer,
  InputType.refinanceList,
  InputType.pipaksaList,
  InputType.namkamList,
];

export default {
  props: {
    value: [String, Object, Array, Boolean, Number],
    model: Object,
    type: String,
    disabled: Boolean,
  },
  components: {
    TableLawyerCourt,
    CourtCovarageArea,
    CourtCustomer,
    RefinanceList,
    UpdateStatusSueSaparateContract,
    UpdateStatusSueSaparateNamKamBangKab,
  },
  data() {
    return {
      inputType: InputType,
    };
  },
};
</script>
