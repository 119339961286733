<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ valid }"
  >
    <v-card
      v-if="showSearch"
      class="search-master"
    >
      <v-card-title>Search</v-card-title>
      <v-card-text>
        <show-model :text="model"></show-model>
        <master-render
          :rows="renderRows"
          v-model="model"
        ></master-render>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="initModel()"
          class="btn-reset"
        >Reset</v-btn>
        <v-btn
          color="primary"
          @click="search()"
          :disabled="!valid"
        >Search</v-btn>
      </v-card-actions>
    </v-card>
  </ValidationObserver>
</template>


<script>
import * as custom from "./../../js/custom";
import { InputType } from "./../../js/constants";
import { mapActions } from "vuex";

export default {
  props: {
    rows: {
      type: Array,
    },
    url: {
      type: String,
      required: true,
    },
    searchOnInit: {
      type: Boolean,
      default: true,
    },
    showSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      model: {},
      loading: null,
    };
  },
  computed: {},
  created() {
    this.initModel();
    requestAnimationFrame(() => {
      this.$refs.observer.reset();
    });
  },
  mounted() {
    if (this.searchOnInit) {
      this.search();
    }
  },
  methods: {
    ...mapActions("loading", ["showLoading", "hideLoading"]),
    initModel() {
      this.model = {};
      custom.createDynamicModel(this, this.model, this.rows);
    },
    async search() {
      if (!this.url) {
        return;
      }

      let valid = await this.$refs.observer.validate();
      if (!valid) {
        return;
      }

      this.showLoading();
      this.$emit("results-search", []);

      // const fullUrl = this.url + "?" + $.param(this.model);
      const fullUrl = this.url + "?" + custom.toQueryString(this.model);

      this.$http
        .get(fullUrl)
        .then((res) => {
          if (!res.hasOwnProperty("items")) {
            throw new Error("Don't have items property");
          }
          this.$emit("results-search", res.items || []);

          this.$emit("clicked-search", _.cloneDeep(this.model));
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
  computed: {
    renderRows() {
      if (this.rows.length) {
        //add event enter to textbox field
        this.rows.forEach((row) => {
          row.forEach((input) => {
            if (input.type === InputType.text) {
              let event = input.event || {};
              event.keyUp = (event, value) => {
                if (event.key === "Enter") {
                  this.search();
                }
              };
              Object.assign(input, { event: event });
            }
          });
        });

        return this.rows;
      } else {
        return this.rows;
      }
    },
  },
};
</script>
