<template>
  <div>
    <v-row>
      <v-col
        offset="1"
        cols="10"
      >
        <show-model :text="innerValue"></show-model>
        <v-simple-table dense>
          <thead>
            <tr class="teal lighten-5">
              <th colspan="100%">
                <v-row>
                  <v-col
                    cols="12"
                    class="d-inline-block"
                  >
                    <render-input
                      ref="area"
                      :input="fieldConfig.area"
                      v-model="selected"
                      :autoFetchItems="false"
                    ></render-input>
                  </v-col>
                  <v-col>
                    <v-chip
                      class="ml-10 mb-3"
                      color="primary"
                      :disabled="selected.length === 0"
                      text
                      @click="clickAdd()"
                    >
                      <v-icon left>add_circle</v-icon>Add Data
                    </v-chip>
                  </v-col>
                </v-row>
              </th>
            </tr>
            <tr>
              <th
                class="text-center"
                width="10%"
              >No.</th>
              <th
                class="text-center"
                width="40%"
              >อำเภอ</th>
              <th
                class="text-center"
                width="30%"
              >ตำบล</th>
              <th
                class="text-center"
                width="20%"
              >Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in sortingItems"
              :key="index"
              :class="{ 'green--text': item.id.includes(starSign) }"
            >
              <td class="text-center">{{ index + 1 }}</td>
              <td>{{ item.district.text }}</td>
              <td>
                {{ item.subDistrict ? item.subDistrict.text : "[ทุกตำบล]" }}
              </td>
              <td class="text-center">
                <v-icon
                  color="error"
                  v-if="canRemove"
                  @click="removeData(item)"
                >delete</v-icon>
              </td>
            </tr>
            <tr v-show="innerValue.length === 0">
              <td
                colspan="100%"
                class="text-center"
              >No Result. Please add</td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getFakeUrl, fakeAbbr } from "./../_helpers/fake-url";
import { InputType } from "./../js/constants";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Object,
      default: true,
    },
  },
  data() {
    return {
      allOptions: [],
      selected: [],
      starSign: "*",
    };
  },
  watch: {
    filteredOption: {
      handler(newVal) {
        this.$refs.area.setItems(newVal);
      },
    },
  },
  created() {
    this.getOptions();
  },
  mounted() {},
  methods: {
    getOptions() {
      //todo: get options
      if (!this.model.id) {
        return;
      }
      let url = `/dropdown/court/${this.model.id}/areas`;
      // url =
      //   getFakeUrl(`dropdown:${getFakeUrl(fakeAbbr.dropdown.court_area)}`) +
      //   url;

      this.$http.get(url).then((res) => {
        this.allOptions = res.items;
      });
    },
    clickAdd() {
      if (this.selected.length > 0) {
        this.addItems();
        this.selected = [];
      }
    },
    removeData(item) {
      let index = this.innerValue.findIndex((x) => x.id === item.id);
      this.innerValue.splice(index, 1);
    },
    addItems() {
      let models = this.selected.map(this.dropDownToModel);
      let itemGbs = _.groupBy(models, "district.id");

      _.toArray(itemGbs).forEach((aDistrict) => {
        this.logicEachGroup(aDistrict);
      });
    },
    dropDownToModel(item) {
      let obj = {
        id: item.id,
        text: item.text,
        district: null,
        subDistrict: null,
      };
      // obj.id = item.id;
      // obj.text = item.text;

      let ids = item.id.split(",").map((x) => x.trim());
      let texts = item.text.split(",").map((x) => x.trim());
      obj.district = {
        id: ids[0],
        text: texts[0],
      };

      if (!item.id.includes(this.starSign)) {
        obj.subDistrict = {
          id: ids[1],
          text: texts[1],
        };
      }

      return obj;
    },
    logicEachGroup(aDistrict) {
      let districtId = aDistrict[0].district.id;
      let starIndex = aDistrict.findIndex((x) => {
        return x.id.includes(this.starSign);
      });
      if (starIndex >= 0) {
        let removedItems = _.remove(this.innerValue, (x) => {
          return x.district.id === districtId;
        });

        this.innerValue.push(aDistrict[starIndex]);
      } else {
        aDistrict.forEach((item) => {
          let existingIndex = this.innerValue.findIndex(
            (x) => x.id === item.id
          );
          //does not exist
          if (existingIndex < 0) this.innerValue.push(item);
        });
      }
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    sortingItems() {
      let value = this.innerValue || [];
      return _.sortBy(value, ["text"]);
    },
    filteredOption() {
      //findout district with all sub-district
      let districtStar = this.innerValue
        .filter((x) => x.id.includes(this.starSign))
        .map((x) => x.district.id);

      let prepareFilters = this.allOptions.map(this.dropDownToModel);
      //remove item that district same with star out
      let filtered = prepareFilters.filter(
        (x) => !districtStar.includes(x.district.id)
      );
      //distinct items
      let differenceId = _.differenceBy(filtered, this.innerValue, "id").map(
        (x) => x.id
      );
      filtered = this.allOptions.filter((x) => differenceId.includes(x.id));

      filtered = _.orderBy(filtered, ["id", "text"], ["asc", "asc"]);
      return filtered;
    },
    canRemove() {
      if (!this.model.id) {
        return true;
      }
      return this.model.permission.canRemove;
    },
    fieldConfig() {
      return {
        area: {
          name: "area",
          text: "พิมพ์ชื่ออำเภอ ตำบล",
          type: InputType.dropdownMultiple,
        },
      };
    },
  },
};
</script>
