import Vue from 'vue'

import Offset from '@/components/helper/Offset'
import Card from '@/components/material/Card'
import ChartCard from '@/components/material/ChartCard'
import Notification from '@/components/material/Notification'
import StatsCard from '@/components/material/StatsCard'


import CustomDatePicker from './custom/custom-datepicker';
import CustomSecureBlock from './custom/custom-secure-block';
import CustomCopyValue from './custom/custom-copy-value';

import ButtonInline from './custom/button-inline';
import ButtonDownload from './custom/button-download';
import CustomToolTip from './custom/custom-tooltip';
import RenderInput from './../views/master-render/render-input';
import ShowModel from './../views/global/show-model'
import MasterRender from './../views/master-render/master-render';
import MasterComponent from './../views/master-render/master-component';


Vue.component(Offset.name, Offset)
Vue.component(Card.name, Card)
Vue.component(ChartCard.name, ChartCard)
Vue.component(Notification.name, Notification)
Vue.component(StatsCard.name, StatsCard)

Vue.component(CustomDatePicker.name, CustomDatePicker)
Vue.component(CustomSecureBlock.name, CustomSecureBlock)
Vue.component(CustomToolTip.name, CustomToolTip)
Vue.component(ButtonInline.name, ButtonInline)
Vue.component(ButtonDownload.name, ButtonDownload)
Vue.component(RenderInput.name, RenderInput)
Vue.component(ShowModel.name, ShowModel)
Vue.component(MasterRender.name, MasterRender)
Vue.component(MasterComponent.name, MasterComponent)
Vue.component(CustomCopyValue.name, CustomCopyValue)



