
import PaymentBulkWithdrawService, { getPaymentModel } from './fake-payment-bulk-withdraw-service';
import { StatusPayment, PaymentType } from '../js/constants';
import { getDropdownItem, getUID, urlToPayload, getCurrentTime } from '../js/custom';
import db from '../_helpers/fake-db';

const collectionRequest = "collectionRequest"
const prefixRef = "BB"

export default {
    search,
    approve,
    createBaiBerk,
    cancelApprove
}



function search(url, opts) {
    let payload = urlToPayload(url)
    let typeId = PaymentType['ค่าวิชาชีพ'].id;
    if (payload['type.id']) {
        typeId = payload['type.id'];
    }

    let items = [];

    let allowedStatus = [];
    if (typeId === PaymentType['ค่าวิชาชีพ'].id) {
        allowedStatus.push(getDropdownItem(StatusPayment['อนุมัติแล้ว']))
        allowedStatus.push(getDropdownItem(StatusPayment['จ่ายเงินแล้ว']))

        let vichacheepItems = PaymentBulkWithdrawService.getRawItems().items;
        items = vichacheepItems

        items.forEach(item => {
            item.type = PaymentType['ค่าวิชาชีพ']
            mockUpSomeData(item);
        })

    } else if (typeId === PaymentType['ค่าธรรมเนียม'].id) {
        allowedStatus.push(getDropdownItem(StatusPayment['รออนุมัติ']))
        allowedStatus.push(getDropdownItem(StatusPayment['อนุมัติแล้ว']))
        allowedStatus.push(getDropdownItem(StatusPayment['จ่ายเงินแล้ว']))

        let advanceItems = db.getCollection(collectionRequest);
        let number = Math.floor(Math.random() * (advanceItems.length + 7)) + advanceItems.length + 2;

        for (let i = 0; i < number; i++) {
            let item = getPaymentModel();
            item.type = PaymentType['ค่าธรรมเนียม']

            let existing = advanceItems[i]
            if (existing) {
                item.id = existing.id;
                item.caseId = existing.caseId;
                item.part = existing.part;
                item.status = existing.status;
                item.description = 'real object';
            } else {
                mockUpSomeData(item);
            }
            items.push(item);
        }
    }

    function mockUpSomeData(item) {
        item.id = getUID();
        item.status = _.sample(allowedStatus)
    }

    items.forEach(item => {
        delete item.statusSue
    })



    return {
        items
    }
}

function approve(url, opts) {
    let request = opts.body;
    let ids = request.ids;

    let existingRequests = db.getCollection(collectionRequest);

    for (let i = 0; i < ids.length; i++) {
        let existing = existingRequests.find(x => x.id);
        if (existing) {
            existing.status = StatusPayment['อนุมัติแล้ว'];
            existing.latestDate = getCurrentTime();
            updateRequestInDB(existing);
        }
    }
}

function cancelApprove() {

}



function createBaiBerk(url, opts) {
    let request = opts.body;
    let ids = request.ids;

    let existingRequests = db.getCollection(collectionRequest);

    for (let i = 0; i < ids.length; i++) {
        let existing = existingRequests.find(x => x.id);
        if (existing) {
            existing.refNumber = getRunningNumber();
            updateRequestInDB(existing);
        }
    }

    return {
        id: 'test bai berk id'
    }
}

function updateRequestInDB(item) {
    let existings = db.getCollection(collectionRequest);
    _.remove(existings, x => {
        return x.id === item.id
    })

    existings.push(item)
    db.updateCollection(collectionRequest, existings);
}


function getRunningNumber() {
    return prefixRef + (Math.floor(Math.random() * 100) + 1).toString().padStart(6, '0');
}