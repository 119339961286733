
export function mockUpAdminUser(userName) {
    if (userName === "thetik;") {
        return {
            valid: true,
            credential: {
                userName: "kawin",
                password: "abcDEF1234!"
            }
        }
    } else {
        return {
            valid: false
        }
    }
}

export function validInternalCode(code) {
    let auth = getToken()
    if (code === "support;") {
        return true;
    } else {
        return false;
    }
}

export const themeColor = "";


import { getToken } from "../js/authorization";
import * as ProfileEmployee from "./profile-employee";
import * as ProfileLawyer from "./profile-lawyer";
export const profile = {
    getProfileView(context) {
        let rows = []
        if (context.isEmployee) {
            rows = ProfileEmployee.getView();
        } else if (context.isLawyer) {
            rows = ProfileLawyer.getView();
        }
        return rows;
    },
    getUrlSubmit(context) {
        let url = ""
        if (context.isLawyer) {
            url = "/Account/profileLawyer";
        } else if (context.isEmployee) {
            url = "/Account/profileEmployee";
        }
        return url;
    }
}