<template>
  <div>
    <render-input :input="fieldConfig.section"></render-input>
    <TableWithAddRecord
      :rows="rows"
      v-model="innerValue"
    ></TableWithAddRecord>
  </div>
</template>

<script>
import { InputType } from "./../../js/constants";
import { getDeptTypes } from "./../../js/dropdown-service";
import TableWithAddRecord from "./../../components/custom/table-with-add-record";

export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    model: Object,
  },
  components: {
    TableWithAddRecord,
  },
  computed: {
    innerValue: {
      get() {
        return this.value || [];
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    rows() {
      return [_.toArray(this.fieldConfig).slice(1)];
    },
    fieldConfig() {
      return {
        section: {
          type: InputType.section,
          text: "Contracts List",
        },
        deptType: {
          name: "type",
          type: InputType.dropdown,
          text: "ประเภทหนี้",
          data: {
            promise: getDeptTypes(),
          },
          rule: {
            required: true,
          },
        },
        number: {
          name: "number",
          type: InputType.text,
          text: "เลขที่",
          rule: {
            required: true,
          },
        },
        amount: {
          name: "amount",
          type: InputType.number,
          decimal: 2,
          text: "ภาระเงินต้น",
          rule: {
            required: true,
            // number: true
          },
        },
      };
    },
  },
};
</script>

