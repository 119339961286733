 <template>
  <div>
    <v-alert border="left" colored-border color="success" elevation="2" v-if="isVerifiedLine">
      <v-row>
        <v-col cols="1" class="text-center">
          <img src="/img/line-green.png" class="icon" alt="Line has been verified" />
        </v-col>
        <v-col class="text-left" align-self="center">
          <b>Line has been verified</b>
        </v-col>
      </v-row>
    </v-alert>

    <v-alert border="left" colored-border color="error" elevation="2" v-else>
      <v-row>
        <div class="float-left ml-5">
          <img src="/img/line-black.png" class="icon float-left" alt="Line has not been verified" />
        </div>
        <div class="text-left pt-0 col align-self-center">
          <b>Line has not been verified</b>
        </div>
      </v-row>
    </v-alert>

    <v-card-text>
      <div v-if="isProfilePage">
        <h4>ขั้นตอนยืนยัน Line Account</h4>

        <ul>
          <li>
            1. เพิ่ม line bot เป็นเพื่อน
            <b>{{lineId}}</b> หรือสแกน QR Code
          </li>
          <img :src="`/img/${lineId}.png`" alt="QR code" height="200" />

          <li>
            2. มุมขวาบน เข้าไปที่
            <b>"Profile"</b> กดปุ่ม
            <b>"Generate Token"</b> จะได้รับรหัสสำหรับยืนยันตัวตน
          </li>
          <li>3. นำรหัสที่ได้จากขั้นตอนก่อนหน้าไปส่งให้ bot ที่ได้เพิ่มเพื่อนไว้</li>
        </ul>
        <br />
        <p>
          <strong>Note 1:</strong>
          รหัสดังกล่าวมีอายุ 30 นาที ถ้าเกินเวลาจะต้องเข้ามาขอรหัสใหม่
        </p>
        <p>
          <strong>Note 2:</strong>
          ถ้ามีการเปลี่ยน Account จะต้องเข้ามายืนยันตัวตนใหม่อีกครั้ง
          โดยระบบจะใช้ Account ใหม่อัตโนมัติ
        </p>
      </div>

      <div class="mt-10" v-if="isProfilePage">
        <h3>Generate Token</h3>

        <v-row>
          <v-col align-self="center" cols="3" class="text-right">
            <v-btn color="primary" @click="generateToken()">
              Generate Token
              <span v-if="isVerifiedLine">&nbsp;again.</span>
            </v-btn>
          </v-col>
          <v-col>
            <v-alert class="mt-5" v-show="lineToken" outlined type="success" text>{{lineToken}}</v-alert>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { getFakeUrl, fakeAbbr } from "../../_helpers/fake-url";
export default {
  data() {
    return {
      id: null,
      isVerifiedLine: false,
      lineToken: ""
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.checkAlreadyVerify();
  },
  methods: {
    checkAlreadyVerify() {
      let url = this.checkAlreadyVerifyUrl;
      // url = getFakeUrl(fakeAbbr.line.checkVerified) + url;

      if (!url) {
        return;
      }

      this.$http.get(url).then(res => {
        this.isVerifiedLine = res;
      });
    },
    generateToken() {
      let url = "/lineNotification/GenerateToken";
      // url = getFakeUrl(fakeAbbr.line.generateToken) + url;

      this.$http.get(url).then(res => {
        this.lineToken = res;
      });
    }
  },
  computed: {
    checkAlreadyVerifyUrl() {
      let url = "";
      switch (this.$route.name) {
        case "profile": {
          url = "/lineNotification/checkAlreadyVerify/profile";
          break;
        }
        case "employee":
        case "lawyer": {
          if (this.id) {
            url = `/lineNotification/checkAlreadyVerify/user/${this.id}`;
          } else {
            log("assume add user mode");
          }
          break;
        }
      }
      return url;
    },
    isProfilePage() {
      return this.$route.name === "profile";
    },
    lineId() {
      return process.env.VUE_APP_LINE_ID;
    }
  }
};
</script>
