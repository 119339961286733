<template>
  <v-app class="light--theme">
    <core-app-bar
      v-if="!showFullScreen"
      class="core-app-bar"
    />

    <core-drawer v-if="!showFullScreen" />

    <v-content :class="{ 'login': isLoginPage }">
      <v-container v-if="!showFullScreen">
        <h1
          class="title-header"
          v-show="title"
        >{{ title }}</h1>
        <router-view />
      </v-container>

      <div
        v-else
        style="height:100%"
        :class="{ 'login-page': isLoginPage }"
      >
        <router-view />
      </div>
      <loading
        :active.sync="$store.state.loading.loading"
        :is-full-page="false"
      ></loading>
    </v-content>
  </v-app>
</template>

<script>
import * as constants from "./js/constants";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "App",
  components: {
    CoreDrawer: () => import("@/components/core/Drawer"),
    CoreAppBar: () => import("@/components/core/AppBar"),
    Loading,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  created() {},
  computed: {
    title() {
      return this.$store.state.pageinfo.title;
    },
    showFullScreen() {
      if (this.$route.meta && this.$route.meta.fullscreen) {
        return true;
      }
      return false;
    },
    isLoginPage() {
      return this.$route.name === "login";
    },
  },
};
</script>

<style lang="scss">
@import "./_specific/style.scss";
</style>


<style>
.v-input .v-input__slot .v-label.v-label--active {
  color: #000 !important;
  top: 5px;
  background: #fff !important;
  padding-left: 10px;
}

.v-input .v-input__slot {
  padding-left: 6px !important;
  padding-right: 3px !important;
}

.v-btn.v-btn--contained {
  color: white !important;
}

.v-btn.v-btn--contained.btn-reset {
  color: #000 !important;
}

.v-input--checkbox .v-input__slot .v-input--selection-controls__input {
  top: -1px;
}

.v-input--checkbox .v-input__slot .v-label {
  color: #000 !important;
  margin-top: -4px !important;
}

.v-text-field .v-label--active {
  max-width: 300% !important;
  left: -10px !important;
}

.v-list-item .v-list-item__subtitle {
  padding-top: 6px;
}

.v-list-item .v-list-item__title + .v-list-item__subtitle {
  padding-left: 4px;
}

.render-input.d-inline-block
  .v-input--checkbox
  .v-input--selection-controls__input {
  top: 2px;
}

/* reduce padding column */
.col {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
}

.col:empty {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.begin-end-block {
  margin-top: -6px;
}
.v-select.v-input--dense .v-chip {
  margin: 2px 4px 0 4px !important;
}

.v-input--is-disabled:not(.v-input--is-readonly),
.v-select.v-text-field input {
  pointer-events: auto !important;
}

.v-input--selection-controls__input + .v-label {
  user-select: auto !important;
}

input[placeholder="."]::-webkit-input-placeholder {
  /* WebKit browsers */
  /* color: #fff !important; */
  color: transparent !important;
}
input[placeholder="."]:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  /* color: #fff !important; */
  color: transparent !important;
  opacity: 1;
}
input[placeholder="."]::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  /* color: #fff !important; */
  color: transparent !important;
  opacity: 1;
}
input[placeholder="."]:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  /* color: #fff !important; */
  color: transparent !important;
}
</style>
