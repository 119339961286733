
export default {
    namespaced: true,
    state: {
        config: null,
        items: [],
        selectedIndex: null,
        selectedId: null,
        dialog: false,
        loading: false,
    },
    mutations: {
        openFormDialog(state, index) {
            state.selectedIndex = index;
            state.dialog = true;
        },
    },
    getters: {

    }
}
