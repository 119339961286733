export default [
    {
        "id": "aa4b777a-1604-4166-32f6-08d7a3114845",
        "isMainLawyer": false,
        "text": "นาย Thosapol Klamthai"
    },
    {
        "id": "c5e6eefe-9835-490e-61c5-08d7a312b25f",
        "isMainLawyer": false,
        "text": "นาย Thosapol2 K2"
    },
    {
        "id": "05c7a91f-2455-40ca-e34e-08d7ab845806",
        "isMainLawyer": false,
        "text": "นาย tiktest tiktest"
    },
    {
        "id": "a2a53a47-2b86-4961-8392-a6c2cb56fcca",
        "isMainLawyer": false,
        "text": "นาย เทพพร ประภัสสร"
    },
    {
        "id": "73ab0533-a04f-4f3a-8682-879aefe24737",
        "isMainLawyer": false,
        "text": "นางสาว เสาวพร เมตตามตะกุล"
    },
    {
        "id": "b6ba039e-5b8c-4a0d-b260-a21ac82eb6c0",
        "isMainLawyer": false,
        "text": "นาย กฤตภาส ทองจำนงค์"
    },
    {
        "id": "044c64c6-d14c-4fc3-ae84-2590294f00b8",
        "isMainLawyer": false,
        "text": "นาย กำพล โซา"
    },
    {
        "id": "ac77e8ad-59d8-4ea8-bf66-18924d5d5c9c",
        "isMainLawyer": false,
        "text": "นางสาว ฆสวันต์ ตั้งรัตนประเสริฐ"
    },
    {
        "id": "15d5fe46-3d8a-484c-982f-509cdf7a6ba5",
        "isMainLawyer": false,
        "text": "นาย จิรภัทร จันทนาคม"
    },
    {
        "id": "5db7fea3-191a-44ff-ba9e-e4ef45dd5d19",
        "isMainLawyer": false,
        "text": "นาย จิรยุทธ์ นาคบาศ"
    },
    {
        "id": "bd72004a-d3d7-42ec-85aa-76c38d1fa99a",
        "isMainLawyer": false,
        "text": "นาย ฉาดฉาน วัฒนะ"
    },
    {
        "id": "f69ab17f-db9c-483d-b8d8-50557d6c8e5f",
        "isMainLawyer": false,
        "text": "นาย ชาญกิจ ทับสาคร"
    },
    {
        "id": "9103d669-8851-44d9-9b92-68e5358e9220",
        "isMainLawyer": false,
        "text": "นาย ณัฐวุฒิ ปินตาฟุ่น"
    },
    {
        "id": "23ea53e9-703c-4ea2-b03b-f39f80ceaaae",
        "isMainLawyer": false,
        "text": "นาย ติณณ์ กิจนาคะเกศ"
    },
    {
        "id": "6ef7ee11-92c9-4f5e-4f57-08d79fb0aa7e",
        "isMainLawyer": false,
        "text": "นาย ทดสอบ ถูกต้อง"
    },
    {
        "id": "3174a3a0-dc8e-4eb5-9d43-2e518547c02f",
        "isMainLawyer": false,
        "text": "นาย ทรงชัย มณีรัตน์"
    },
    {
        "id": "f6baaf7f-ef6f-41db-91d1-15b3661f4b05",
        "isMainLawyer": false,
        "text": "นางสาว ทิพย์จันทร์ สายยศ"
    },
    {
        "id": "7e61013d-c526-4004-ba26-ecd1ba311006",
        "isMainLawyer": false,
        "text": "นาย ธงไชย หงษ์สุรกุล"
    },
    {
        "id": "1dbdeba5-baa3-4557-89ce-5086a4ed886b",
        "isMainLawyer": false,
        "text": "นาย ธนากิตต์ อุทาทัย"
    },
    {
        "id": "856ded46-830a-43f1-8339-d410d3828620",
        "isMainLawyer": false,
        "text": "นาย ธปพนธ์ ห้วยนุ้ย"
    },
    {
        "id": "3445f641-ce6b-405e-8470-de926dc8946b",
        "isMainLawyer": false,
        "text": "นาย ธีรนาถ ดาวังปา"
    },
    {
        "id": "01bc93a6-72b0-423b-94bf-fbeb09c314c3",
        "isMainLawyer": false,
        "text": "นาย ธีราวุธ นกเหลือ"
    },
    {
        "id": "22604483-b253-4da9-1568-08d7a2f20d66",
        "isMainLawyer": false,
        "text": "นาย นิพ1ล อนันตพั1นธ์"
    },
    {
        "id": "e87d6fd6-e69a-4228-b4fa-03761d453e6b",
        "isMainLawyer": false,
        "text": "นาย นิพล อนันตพันธ์"
    },
    {
        "id": "080bbf5c-80ae-4785-b844-f535ddf71fad",
        "isMainLawyer": false,
        "text": "นาย บุญชัย เมธีทัศนีย์"
    },
    {
        "id": "dc71046b-3fe2-4fe8-8e12-8ad24ae9e6ff",
        "isMainLawyer": false,
        "text": "นางสาว ปภารัตษร รวิรัชพร"
    },
    {
        "id": "282893bd-cf25-4e27-8e49-12bdfb5e6f54",
        "isMainLawyer": false,
        "text": "นาย ปริญญา ชุมพล"
    },
    {
        "id": "79262ecf-b577-4310-b73b-f88fbff316f7",
        "isMainLawyer": false,
        "text": "นางสาว ปวีณ์นุช โรยนิมิตร"
    },
    {
        "id": "8d910ed3-207f-4609-9884-9927b4aeeb5b",
        "isMainLawyer": false,
        "text": "นาย พงษ์พิพัฒน์ พลพวง"
    },
    {
        "id": "13d67c55-08eb-4056-b184-40c0a5db74a6",
        "isMainLawyer": false,
        "text": "นางสาว พรนภา เกษมุณี"
    },
    {
        "id": "b5c2e35b-c314-4a63-8620-9f4309d06b16",
        "isMainLawyer": false,
        "text": "นาย พิรพงศ์ จัตตามาศ"
    },
    {
        "id": "5a7c1822-6b4a-4703-8f58-0858a3dbaebb",
        "isMainLawyer": false,
        "text": "นาย พีระพัฒน์ เมธีกุล"
    },
    {
        "id": "a0b6addb-2ecd-4b4f-99d3-5632f7fd08c6",
        "isMainLawyer": false,
        "text": "นาย วัชรินทร์ ดวงแก้ว"
    },
    {
        "id": "5c330bdf-04b1-4ff7-0b2c-08d7a6e7e864",
        "isMainLawyer": false,
        "text": "นางสาว วันนา แม่ง"
    },
    {
        "id": "752a5476-6044-4b2c-92f4-80db3e853e99",
        "isMainLawyer": false,
        "text": "นาย วีรวัฒน์ บุตรจันทร์"
    },
    {
        "id": "6746dcd0-3f82-4c11-a562-7d472d11a795",
        "isMainLawyer": false,
        "text": "นาย ศรัณยู หมอยา"
    },
    {
        "id": "6805722e-845a-4646-9c58-d922f7cf8315",
        "isMainLawyer": false,
        "text": "นาย สราวุธ ทองหยัด"
    },
    {
        "id": "c558c5da-4a86-4936-8bc0-ee8f0bf1bc05",
        "isMainLawyer": false,
        "text": "นางสาว สายสุรีย์ สมทรง"
    },
    {
        "id": "4723e4a8-81a8-47fd-844b-d7568fb038da",
        "isMainLawyer": false,
        "text": "นาย สิทธิพล ชูจันทร์"
    },
    {
        "id": "161565ee-fd89-439e-b47c-5e2a9c4546d5",
        "isMainLawyer": false,
        "text": "นาย สิปปภาส ชาญธีระเดช"
    },
    {
        "id": "09983930-3a50-44f5-9954-610978340e5b",
        "isMainLawyer": false,
        "text": "นาย สุเมธ ปัญญา"
    },
    {
        "id": "0455b057-cb5a-460a-9395-7821e52c5dde",
        "isMainLawyer": false,
        "text": "นางสาว สุภาวดี พันธวงศ์"
    },
    {
        "id": "91aced7f-abcf-40fb-b973-f29892d804e8",
        "isMainLawyer": false,
        "text": "นาย สุรศักดิ์ เตชาพินิจ"
    },
    {
        "id": "17ffa0d7-92d8-4c1a-b4c6-dfcdf56153a4",
        "isMainLawyer": false,
        "text": "นางสาว หทัยชนก จ๊ะสุนา"
    },
    {
        "id": "9a55942f-8be0-4173-9f27-99f20bc02255",
        "isMainLawyer": false,
        "text": "อื่นๆ หัสนัย บุญมา"
    },
    {
        "id": "ca83651d-ccfa-40e2-8085-5b1756648915",
        "isMainLawyer": false,
        "text": "นาย อธิปไตย นุ่มถือสัตย์"
    },
    {
        "id": "05f9d2b4-c23e-42b0-b956-dff77156a487",
        "isMainLawyer": false,
        "text": "นาย อัครชัย จันทร์ขอนแก่น"
    },
    {
        "id": "a45beb47-0da6-495a-96c1-2077b51fc477",
        "isMainLawyer": false,
        "text": "นาย อัจฉริยะ พรวรรณภากิจ"
    }
]