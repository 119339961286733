<template>
  <div>
    <show-model :text="value"></show-model>
    <v-row class="mb-10">
      <v-col>
        <render-input
          class="white label-summary"
          :input="summaryConfig.field1"
          v-model="summaryModel.field1"
        >
        </render-input>
      </v-col>
      <v-col>
        <render-input
          class="white label-summary"
          :input="summaryConfig.field2"
          v-model="summaryModel.field2"
        >
        </render-input>
      </v-col>
      <v-col>
        <render-input
          class="white label-summary"
          :input="summaryConfig.field3"
          v-model="totalField3"
        >
        </render-input>
      </v-col>
      <v-col>
        <render-input
          class="white label-summary"
          :input="summaryConfig.field4"
          v-model="totalField4"
          :class="[{'red--text':totalField4 < summaryModel.field2}]"
        >
        </render-input>
      </v-col>
    </v-row>

    <TableWithAddRecord
      class="mt-1"
      style="margin-top:-40px;"
      :rows="rows"
      v-model="items"
      :showAdd="!disabled"
      :showRemove="!disabled"
    >
      <template v-slot:content="obj">
        <v-row>
          <v-col>
            <render-input
              :disabled="disabled"
              :input="fieldConfig.field1"
              v-model="obj.item.field1"
            ></render-input>
          </v-col>
          <v-col>
            <render-input
              :disabled="disabled"
              :input="fieldConfig.field2"
              v-model="obj.item.field2"
            ></render-input>
          </v-col>
          <v-col>
            <render-input
              :disabled="disabled"
              :input="fieldConfig.field3"
              v-model="obj.item.field3"
            ></render-input>
          </v-col>
          <v-col>
            <render-input
              :disabled="disabled"
              :input="fieldConfig.field4"
              v-model="obj.item.field4"
            ></render-input>
          </v-col>
        </v-row>
      </template>
    </TableWithAddRecord>
  </div>
</template>

<script>
import { InputType, PipaksaResultType } from "./../../js/constants";
import { getDeptTypes } from "./../../js/dropdown-service";
import TableWithAddRecord from "./../../components/custom/table-with-add-record";
import { getFakeUrl, fakeAbbr } from "./../../_helpers/fake-url";
import { sumList } from "../../js/custom";

export default {
  props: {
    value: {
      type: Object,
    },
    model: Object,
    disabled: Boolean,
  },
  components: {
    TableWithAddRecord,
  },
  data() {
    return {
      percent: 5,
    };
  },
  created() {
    if (this.items.length > 0) {
      return;
    }
    //todo : get ทุนทรัพย์
  },
  methods: {
    conditionShowRedText(value, func) {
      let result = false;
      let number = Number(value);
      if (number && number > 0) {
        result = func(value);
      }
      return result;
    },
  },
  computed: {
    state() {
      return this.$store.state.caseinfo;
    },
    summaryModel: {
      get() {
        let result = {};
        if (this.value && this.value.summary) {
          result = this.value.summary;
        }

        return result;
      },
      set(val) {
        this.value.summary = val;
      },
    },
    items: {
      get() {
        let result = [];
        if (this.value && this.value.items) {
          result = this.value.items;
        }

        return result;
      },
      set(val) {
        this.value.items = val;
      },
    },
    totalField3() {
      let total = sumList(this.items, "field3");
      return total;
    },
    totalField4() {
      let total = sumList(this.items, "field4");
      return total;
    },
    summaryConfig() {
      return {
        field1: {
          text: "รวมทุนทรัพย์",
          name: "field1",
          type: InputType.label,
          rule: { number: true },
        },
        field2: {
          text: "รวมต้นเงิน",
          name: "field2",
          type: InputType.label,
          rule: { number: true },
        },
        field3: {
          text: "รวมยอดเงินตามคำพิพากษา",
          name: "field3",
          type: InputType.label,
          rule: { number: true },
        },
        field4: {
          text: "รวมต้นเงินตามคำพิพากษา",
          name: "field4",
          type: InputType.label,
          rule: { number: true },
        },
      };
    },
    fieldConfig() {
      //todo: change label afterwards
      return {
        field1: {
          name: "field1",
          text: "อัตราดอกเบี้ยตามคำพิพากษา",
          type: InputType.number,
          decimal: 2,
          rule: {
            // number: true,
            required: true,
          },
          suffixText: "%",
        },
        field2: {
          name: "field2",
          text: "วันเริ่มชำระ",
          type: InputType.datePicker,
          rule: {
            required: true,
          },
        },
        field3: {
          name: "field3",
          text: "ยอดเงินตามคำพิพากษา",
          type: InputType.number,
          decimal: 2,
          rule: {
            // number: true,
            required: true,
          },
        },
        field4: {
          name: "field4",
          text: "ต้นเงินตามคำพิพากษา",
          type: InputType.number,
          decimal: 2,
          rule: {
            // number: true,
            required: true,
          },
        },
      };
    },

    rows() {
      return [_.toArray(this.fieldConfig)];
    },
  },
};
</script>


<style >
.red--text .v-input input {
  color: #ff5722 !important;
}

.red--text
  .v-input.v-input--is-disabled
  .v-input__slot
  .v-text-field__slot
  input {
  color: #ff5722 !important;
}

.red--text .v-list-item__content .v-list-item__subtitle {
  color: #f44336 !important;
  caret-color: #f44336 !important;
}

.label-summary {
  padding-top: 15px;
  padding-bottom: 0px;
  padding-bottom: 0px;
  padding-right: 24px;
}
</style>
