export default [
    {
        id: '01',
        text: 'ศาลเขต 1'
    },
    {
        id: '02',
        text: 'ศาลเขต 2'
    },
    {
        id: '03',
        text: 'ศาลเขต 3'
    },
    {
        id: '04',
        text: 'ศาลเขต 4'
    }, {
        id: '05',
        text: 'ศาลเขต 5'
    }
]