<template>
  <div
    v-if="$store.getters['pageinfo/isSupportMode']"
    style="border : 1px solid black"
    class="d-inline-block"
  >
    <!-- https://stackoverflow.com/a/67043230 -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            v-bind="attrs"
            @click.native.stop="toClick(on, $event, item?.id)"
            class="pa-0"
            :class="{'d-inline-block': inline}"
            text
            x-small
            :style="{ 'color': hover ? 'red' : 'blue' }"
          >Id</v-btn>
        </v-hover>
      </template>

      <span>Copied</span>
    </v-tooltip>

    <div
      class="mt-1"
      v-if="!inline"
    ></div>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            v-bind="attrs"
            @click.native.stop="toClick(on, $event, JSON.stringify(item))"
            class="pa-0"
            :class="{'d-inline-block': inline}"
            text
            x-small
            :style="{ 'color': hover ? 'red' : 'blue' }"
          >Object</v-btn>
        </v-hover>
      </template>

      <span>Copied</span>
    </v-tooltip>

  </div>
</template>
<!-- fix when use on top event others such as on panel header with expand -->
<!-- https://stackoverflow.com/a/55058832 -->
<script>
export default {
  name: "custom-copy-value",
  props: {
    item: Object,
    inline: {
      type: Boolean,
      default: false,
    },
    alwaysDisplay: Boolean,
  },
  mounted() {},
  beforeDestroy() {
    clearTimeout(this._timerId);
  },
  methods: {
    toClick({ mouseenter, mouseleave }, e, text) {
      //https://stackoverflow.com/a/71811049
      clearTimeout(this._timerId);
      mouseenter(e);
      this.copyToClipboard(text);
      this._timerId = setTimeout(() => mouseleave(e), 300);
    },
    copyToClipboard(text) {
      var dummy = document.createElement("textarea");
      dummy.addEventListener("focusin", (e) => e.stopPropagation()); // https://github.com/euvl/v-clipboard/issues/18    fix bug on v-diablog
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);
    },
    tik({ mouseenter, mouseleave }, e, text) {
      //https://stackoverflow.com/a/71811049
      clearTimeout(this._timerId);
      mouseenter(e);
      copyToClipboard(text);
      this._timerId = setTimeout(() => mouseleave(e), 300);

      copyToClipboard = (text) => {
        var dummy = document.createElement("textarea");
        dummy.addEventListener("focusin", (e) => e.stopPropagation()); // https://github.com/euvl/v-clipboard/issues/18    fix bug on v-diablog
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
      };
    },
  },
};
</script>